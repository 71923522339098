import i18n from '@mv-submodules/inplant-config/config/i18n'
import { showNotification } from '@mv-submodules/inplant-components-fe/ui/components/MVNotification/Notification'

export const popupNotification = (options: {title?:string, type: 'warning' | 'danger'; text?: string }) => {
  const {type,text,title} = options
  showNotification({
    duration: 5000,
    type,
    title: title || i18n.t(`auth.notification.${options.title}`),
    message: text,
  })
}

/**
 * Console notications
 *
 * It is usually used to notify missing configurations or setups to be completed
 * @param message string
 * @param title string
 * @param type string
 */
export const consoleNotification = (
  message: string,
  title?: string,
  type: 'alert' | 'success' | 'warning' | 'error' | 'danger' | 'info' | 'information' = 'warning'
) => {
  const consoleColors = {
    alert: '#dc8822',
    success: '#22aa33',
    warning: '#dc8822',
    error: '#cc2222',
    danger: '#cc2222',
    info: '#ccaa66',
    information: '#ccaa66',
  }

  if (title) {
    console.group( // tslint:disable-line
      `%c${title}`,
      `background-color: ${consoleColors[type]} ; color: #ffffff ; font-weight: bold ; padding: 4px ;`
    )
  }
  console.log(`%c${message}`, `font-weight: bold ; padding: 4px ;`) // tslint:disable-line
  if (title) {
    console.groupEnd() // tslint:disable-line
  }
}
