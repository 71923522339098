import { useAppSelector } from '@mv-submodules/inplant-core-fe/redux/reducers'
import { ExternalPasswordRecovery } from '@mv-submodules/inplant-core-fe/types/passwordRecovery'
import Text from '@mv-submodules/inplant-components-fe/ui/components/MVText/Text'
import { WithTranslation } from '@mv-submodules/inplant-components-fe/mvtypes/base'
import React from 'react'

const PasswordRecovery = (props: WithTranslation) => {
  const externalPasswordRecovery: ExternalPasswordRecovery | undefined = useAppSelector(
    store => (store?.config as any)?.generic?.externalPasswordRecovery
  )
  const { t, base } = props.translation

  if (externalPasswordRecovery) {
    return (
      <div className={'mv-login__password-recovery'}>
        <Text
          text={`|***${t(`${base}.passwordRecovery`)}***|`}
          links={[{ href: externalPasswordRecovery.link }]}
          alignment={'center'} />
      </div>)
  }

  // vidim password reovery not available
  return null
}

export default PasswordRecovery
