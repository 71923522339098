import i18n from '@mv-submodules/inplant-config/config/i18n'
import initFetchWrapper from '@mv-submodules/inplant-config/config/fetch'
import 'bootstrap'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store from './store/configureStore'
import { persistStore } from 'redux-persist'
import Layout from '@mv-submodules/inplant-core-fe/ui/components/widgets/Layout/Layout'
import { unregister } from './registerServiceWorker'
import './index.css'
import { onStartup } from './mv-submodules/inplant-config/config/onStartup'

initFetchWrapper(i18n)

const persistor = persistStore(store)

onStartup().then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Layout />
      </PersistGate>
    </Provider>,
    document.getElementById('root') as HTMLElement
  )
})
unregister()
