// * -------------------------------- NPM --------------------------------------
import React from 'react'
import { useEffect, useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import AppendIcon from './AppendIcon'
import useGenericInputHook from '../../../../mvfunctions/hooks/useGenericInputHook'
import { IContinueTimeComponents, IInputComponent, InputComponentTypes, OtherInputProps } from '../types'
import { IconProps } from '../../../../mvtypes/base'
import { getUniqueId } from '../../../../mvfunctions/helpers/stringHelper'
import { IconComponentServiceProps } from '../../../../services/icon'

/**
 * !Don't use this component, Use only Input
 *
 * This component expose a @callback onChange that return the current value and the state of the input
 *
 * Use the props @param controls to create the controls that the input need to satisfy to be valid
 */
type OtherProps = IconProps & OtherInputProps
type Props = (IInputComponent | IContinueTimeComponents) & OtherProps
const InputComponent: React.FC<Props> = props => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const inputHook = useGenericInputHook(props.controls?.map(c => ({ control: () => c.control(value), error: c.error })))

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const [value, setValue] = useState<string>(props.initialValue || '')
  const [uniqueId] = useState(props.id || getUniqueId(props.label))

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    if (props.initialValue !== null && props.initialValue !== undefined && props.overrideValue) {
      setValue(props.initialValue)
    }
  }, [props.initialValue])

  useEffect(() => {
    inputHook.retry()
  }, [value])

  useEffect(() => {
    if (props.onChangeState) {
      props.onChangeState(inputHook.state)
    }
  }, [inputHook.state])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault()
    let currentTargetValue = e.currentTarget.value
    if (!currentTargetValue) {
      currentTargetValue = ''
    }
    setValue(currentTargetValue)
    props.onChange?.(currentTargetValue, e)
  }

  const handleClearValue = () => {
    setValue('')
    props.onChange?.('')
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <InputComponentFactory
      {...props}
      handleClearValue={handleClearValue}
      uniqueId={uniqueId}
      handleChange={handleChange}
      value={value}
    />
  )
}

interface InputComponentFactoryProps {
  uniqueId: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleClearValue: () => void
  value: string
}

const InputComponentFactory = (props: Props & InputComponentFactoryProps) => {
  const inputClassName = `mv-form-control ${props.icon ? 'mv-input-group__form-control' : ''} ${props.validity || ''}`
  if (props.kind === 'input' && props.rows !== undefined) {
    return <TextAreaInputComponent {...props} className={inputClassName} />
  }

  if (props.kind === 'input') {
    return <GeneralInputComponent {...props} className={inputClassName} />
  }

  if (props.kind === 'time') {
    return <ContinueTimeInputComponent {...props} className={inputClassName} />
  }

  return <GeneralInputComponent {...props} className={inputClassName} />
}

const TextAreaInputComponent = (
  props: IInputComponent & OtherProps & InputComponentFactoryProps & { className: string }
) => {
  return (
    <textarea
      onKeyDown={props.onKeyDown}
      name={props.name}
      readOnly={props.readonly}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      autoFocus={props.autoFocus}
      disabled={props.disabled || props.isDisable}
      onChange={props.handleChange}
      id={props.uniqueId}
      className={props.className}
      value={props.value}
      rows={props.rows}
      data-testid="input"
    />
  )
}

const GeneralInputComponent = (
  props: IInputComponent & OtherProps & InputComponentFactoryProps & { className: string }
) => {
  const { handleClearValue } = props

  const generateInputType = () => {
    switch (props.type) {
      case 'integer':
        return 'number'
      default:
        return props.type || 'text'
    }
  }

  const handleVisibilityPassword = (setVisible: boolean) => {
    setType(setVisible ? 'text' : 'password')
  }

  const [type, setType] = useState<InputComponentTypes>(generateInputType())

  const defaultOnKeyDown = () => {
    if (props.type === 'integer') {
      return (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === '.' || e.key === ',') {
          e.preventDefault()
        }
      }
    }
    return undefined
  }

  const setupAppendIcon = (): [IconComponentServiceProps | undefined, { dataTestId: string }] => {
    if (props.customAppendIcon) {
      return [props.customAppendIcon, { dataTestId: 'input-append-button' }]
    }
    if (props.clearable) {
      return [
        {
          icon: 'times-circle',
          onClick: handleClearValue,
        },
        { dataTestId: 'input-clear-button' },
      ]
    }
    return [undefined, { dataTestId: '' }]
  }

  const [appendIcon, { dataTestId }] = setupAppendIcon()

  return (
    <>
      <AppendIcon
        customAppendIcon={appendIcon}
        dataTestId={dataTestId}
        handleVisibilityPassword={handleVisibilityPassword}
        value={props.value}
        type={props.type}
        showPassword={type !== 'password'}
      />
      <input
        onKeyDown={props.onKeyDown || defaultOnKeyDown()}
        name={props.name}
        readOnly={props.readonly}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        autoFocus={props.autoFocus}
        disabled={props.disabled || props.isDisable}
        step={props.step}
        max={props.max}
        min={props.min}
        autoComplete={props.autocomplete}
        pattern={props.pattern}
        id={props.uniqueId}
        className={props.className}
        onChange={props.handleChange}
        type={type}
        value={props.value}
        data-testid="input"
      />
    </>
  )
}

const ContinueTimeInputComponent = (
  props: IContinueTimeComponents & OtherProps & InputComponentFactoryProps & { className: string }
) => {
  return (
    <input
      onKeyDown={props.onKeyDown}
      name={props.name}
      readOnly={props.readonly}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      autoFocus={props.autoFocus}
      disabled={props.disabled || props.isDisable}
      step={props.step}
      max={props.max}
      min={props.min}
      pattern={props.pattern}
      autoComplete={props.autocomplete}
      id={props.uniqueId}
      className={props.className}
      onChange={props.handleChange}
      type={props.type}
      value={props.value}
      data-testid="input"
    />
  )
}

// * -------------------------------- DEFAULT PROPS --------------------------------------
InputComponent.defaultProps = {
  type: 'text',
}

// * -------------------------------- MEMOIZATION --------------------------------------
export default React.memo(InputComponent, (prevProps, nextProps) => {
  if (prevProps.initialValue !== nextProps.initialValue && nextProps.overrideValue) {
    return false
  }
  if (
    prevProps.validity === nextProps.validity &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.readonly === nextProps.readonly && 
    prevProps.isDisable === nextProps.isDisable
  ) {
    return true
  }
  return false
})
