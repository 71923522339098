// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import { ContainerProvider } from '@mv-submodules/inplant-components-fe/services/binding'

// * -------------------------------- MODULE --------------------------------------
import initServices from '@mv-submodules/inplant-core-fe/services/initServices'
import SegmentsProvider from '../BreadcrumbsTitle/BreadcrumbsTitle'
import CountersProvider from '../Counters/Counters'

const container = initServices()
/**
 * Inject all the provider used around the application.
 * (Used on Layout component)
 */
const InitProvider = <P extends object>(Component: React.ComponentType<P>) => (props: P) => (
  <ContainerProvider container={container}>
    <CountersProvider>
      <SegmentsProvider>
        <Component {...props} />
      </SegmentsProvider>
    </CountersProvider>
  </ContainerProvider>
)

export default InitProvider
