export const getSlugByIndex = (index: 1 | 2 | 3 | 4 | 5): string => {
  try {
    return window.location.pathname.split('/')[index]
  } catch {
    return ''
  }
}

export const getPageSlug = () => {
  return getSlugByIndex(2)
}

export const getModuleSlug = () => {
  return getSlugByIndex(1)
}
