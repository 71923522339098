import generateRoutes, { AllPages } from './ui/routes'

export { default as generateTutelageRoutes,AllPages as TutelagePages } from './ui/routes'
export { default as reducers } from './redux/reducers'

export const maintenancesRoutes = generateRoutes('maintenances', [
  AllPages.myTaskList,
  AllPages.toPlan,
  AllPages.planned,
  AllPages.ambiente,
  AllPages.sicurezza,
  AllPages.manutenzioni,
  AllPages.renewals,
  AllPages.expired,
  AllPages.archive,
  AllPages.calendar,
  AllPages.controls,
  AllPages.creation,
])
