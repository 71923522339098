import { useEffect, useState } from 'react'
import {
  ExternalRouteProps,
  RouteProps,
} from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/SideBar/SideBar'
import { useAppSelector } from '@mv-submodules/inplant-core-fe/redux/reducers'
import { composeRoutes } from '@mv-submodules/inplant-core-fe/functions/aclGenerator'
import getRoutes, { aclRedirect } from '@mv-submodules/inplant-config/config/routes'
import { User } from '@mv-submodules/inplant-core-fe/types'
import { IconName } from '@mv-submodules/inplant-components-fe/services/icon'

const useRouting = () => {
  const loginSuccess: boolean = useAppSelector(state => state.auth.loggedIn)
  const useNewLogin: boolean = useAppSelector(state => state.config.generic?.useNewLogin || false)
  const storageLoginSuccessPath: string = useAppSelector(state => state.config.generic.loginSuccessPath)

  const user: User | null = useAppSelector(state => state.auth.user)

  const [routes, setRoutes] = useState<RouteProps[]>([])
  const [sidebarRoutes, setSidebarRoutes] = useState<RouteProps[]>([])
  const [loginSuccessPath] = useState<string>(aclRedirect(storageLoginSuccessPath))
  const [externalLinkRoutes] = useState<RouteProps[]>(getExternalLinksFromEnv())

  function getRoutesVisibleForUser(moduleRoutes: RouteProps[]): RouteProps[] {
    return moduleRoutes.map(r => {
      return (
        (r.children && {
          ...r,
          children: r.children.filter((subR: RouteProps) => {
            return (
              subR.visible &&
              (!subR.aclActionKey || (subR.aclActionKey && !user?.forbiddenActions.includes(subR.aclActionKey)))
            )
          }),
        }) || { ...r, children: undefined }
      )
    })
  }

  function getExternalLinksFromEnv(): RouteProps[] {
    const navLinks: string | undefined =
      (window as any).NAVIGATION_EXTERNAL_LINK || process.env.REACT_APP_NAVIGATION_EXTERNAL_LINK

    const r: RouteProps[] = []

    if (navLinks) {
      const navArray = JSON.parse(navLinks)
      const jwt = localStorage.getItem('jwt')
      if (Array.isArray(navArray)) {
        navArray.forEach((obj: ExternalRouteProps) => {
          if ((obj.acls && obj.acls.every(acl => user?.can(acl))) || !obj.acls) {
            r.push({
              visible: true,
              i18nkey: `config.externalLinks.${obj.i18nkey}`,
              path: `${obj.origin || window.location.origin}${obj.pathname}`,
              external: true,
              icon: obj.iconName as IconName,
              sortOrder: obj.sortOrder,
              requiredAuth: obj.requiredAuth && jwt ? { required: true, token: jwt } : { required: false },
            })
          }
        })
      }
    }

    return r
  }

  useEffect(() => {
    let r = getRoutes(user?.roles || [])

    if (useNewLogin && loginSuccess && user) {
      // acl are set from BE login response
      r = composeRoutes([...r], user)
    }

    setRoutes([...r])
    setSidebarRoutes(
      [...getRoutesVisibleForUser(r), ...externalLinkRoutes].sort((a, b) => {
        if (a.sortOrder && b.sortOrder) {
          return a.sortOrder - b.sortOrder
        } else if (b.sortOrder) {
          return 1
        } else if (a.sortOrder) {
          return -1
        }
        return 0
      })
    )
  }, [loginSuccess])

  return { routes, sidebarRoutes, loginSuccessPath, externalLinkRoutes }
}

export default useRouting
