// * -------------------------------- NPM --------------------------------------
import { lazy } from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import { RouteProps } from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/SideBar/SideBar'
import { IconName } from '@mv-submodules/inplant-components-fe/services/icon'

// * -------------------------------- CORE --------------------------------------
import { createStoreKey } from '@mv-submodules/inplant-core-fe/ui/components/widgets/Counters/Counters'

// * -------------------------------- MODULE --------------------------------------
const TaskCreationPageView = lazy(() => import('./components/views/TaskCreation/TaskCreationPageView'))
const TaskDetailPageView = lazy(() => import('./components/views/TaskDetail/TaskDetailPageView'))
const TaskListPageView = lazy(() => import('./components/views/TaskList/TaskListPageView'))
const TemplateListPageView = lazy(() => import('./components/views/TemplateList/TemplateListPageView'))
const TemplateDetailPageView = lazy(() => import('./components/views/TemplateDetail/TemplateDetailPageView'))


function generateRoutes(module: Module, pageSlugs: AllPages[]): RouteProps {
  return {
    path: `/${module}/${pageSlugs[0]}`,
    i18nkey: `tutelage.modulesSlug.${module}.plural`,
    icon: generateIcon(module),
    breadcrumbPath: module,
    visible: true,
    children: pageSlugs.reduce((acc: RouteProps[], pageSlug) => {
      return acc.concat(
        {
          path: `/${module}/${pageSlug}`,
          exact: true,
          i18nkey: `tutelage.navigation.${pageSlug}`,
          breadcrumbPath: pageSlug,
          i18nOptions: {
            value: {
              label: `tutelage.modulesSlug.${module}.${pageSlug === AllPages.creation ? 'singular' : 'plural'}`,
              needTranslation: true,
            },
          },
          component: selectPageToGenerate(pageSlug),
          visible: true,
          counter: {
            storeKey: createStoreKey(module,pageSlug),
          },
        },
        ...createDerivatePages(module, pageSlug)
      )
    }, []),
  }
}

const createDerivatePages = (module: Module, pageSlug: AllPages): RouteProps[] => {
  switch (pageSlug) {
    case 'creation':
      return []
    case 'controls':
      return [
        {
          path: `/${module}/${pageSlug}/:id`,
          exact: true,
          component: TemplateDetailPageView,
          visible: false,
          breadcrumbPath: ':id',
          i18nkey: 'tutelage.navigation.detail',
        },
        {
          path: `/${module}/${pageSlug}/create`,
          exact: true,
          component: TemplateDetailPageView,
          visible: false,
          breadcrumbPath: 'create',
          i18nkey: 'tutelage.navigation.createTemplate',
        },
      ]
    default:
      return [
        {
          path: `/${module}/${pageSlug}/:id`,
          exact: true,
          component: TaskDetailPageView,
          visible: false,
          breadcrumbPath: ':id',
          i18nkey: 'tutelage.navigation.detail',
        },
        {
          path: `/${module}/${pageSlug}/:id/edit`,
          exact: true,
          component: TaskCreationPageView,
          breadcrumbPath: 'edit',
          visible: false,
          i18nkey: 'tutelage.navigation.edit',
        },
      ]
  }
}

const selectPageToGenerate = (pageSlug: AllPages) => {
  switch (pageSlug) {
    case 'creation':
      return TaskCreationPageView
    case 'controls':
      return TemplateListPageView
    default:
      return TaskListPageView
  }
}

const generateIcon = (module: Module): IconName => {
  switch (module) {
    case 'maintenances':
      return 'calendar'
    default:
      return 'calendar'
  }
}

export enum AllPages {
  myTaskList = 'my-task-list',
  toConfirm = 'to-confirm',
  toPlan = 'to-plan',
  planned = 'planned',
  renewals = 'renewals',
  ambiente = 'ambiente',
  sicurezza = 'sicurezza',
  manutenzioni = 'manutenzioni',
  toBeValidated = 'to-be-validated',
  ongoing = 'ongoing',
  expired = 'expired',
  archive = 'archive',
  calendar = 'calendar',
  controls = 'controls',
  creation = 'creation',
  createTemplate = 'controls/create',
}

export type Module = 'maintenances' | 'anomalies'

export default generateRoutes
