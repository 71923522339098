// * -------------------------------- NPM --------------------------------------
import React from 'react'
import { IsLoading } from '../../../mvtypes/base'

// * -------------------------------- MODULE --------------------------------------
import PlaceholderLoader from '../MVLoaders/PlaceholderLoader'

interface Props extends IsLoading {
  title: string
  /**
   * Levels are referred to the HTML tags. Level:1 is equal to h1, and so on
   */
  level: 1 | 2 | 3 | 4 | 5 | 6
}

const Title = (props: Props) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { level, title, isLoading } = props

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs -----------------------------------------------
  // * ----------------------------------------------------------------------------------------
  
  const renderPossibleCase = () => {
    if(isLoading) {
      return <PlaceholderLoader length={title.length} />
    }
    return renderContentWithHeading()
  }
  
  const renderContentWithHeading = () => {
    const name = `mv-title`
    switch (level) {
      case 1:
        return <h1 className={name}>{title}</h1>
      case 2:
        return <h2 className={name}>{title}</h2>
      case 3:
        return <h3 className={name}>{title}</h3>
      case 4:
        return <h4 className={name}>{title}</h4>
      case 5:
        return <h5 className={name}>{title}</h5>
      case 6:
        return <h6 className={name}>{title}</h6>
    }
  }

  return renderPossibleCase()
}

// * ------------------------------------------
// * Helper Components that pre-fill some props
// * ------------------------------------------

export const SectionTitle = (props: { title: string, isLoading?: boolean }) => {
  return <Title level={3} title={props.title} isLoading={props.isLoading} />
}

export default Title
