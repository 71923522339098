import { getContainer } from '@mv-submodules/inplant-components-fe/services/binding'
import initIcons from './icons'
import initTranslation from './translations'

const initServices = () => {
  const container = getContainer()

  initTranslation(container)
  initIcons(container)

  return container
}

export default initServices
