// * -------------------------------- NPM --------------------------------------
import React, { useEffect, useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Alert from '../../../ui/components/MVAlert/Alert'
import Flex, { AlignContent, AlignItems, Direction, JustifyContent, StretchColumn } from '../MVFlex/Flex'
import FlexItem, { AlignSelf, IFlexItem, renderFlexItem } from '../MVFlex/FlexItem'
import IconComponent from '../MVIcon/Icon'
import MultiselectDown from './MultiselectDown/MultiselectDown'
import SelectionLogic, { MultiSelectUpLogic, SingleNodeLogic, SingleSelectDownLogic } from './PrivateComponent/SelectionLogic'
import Text from '../MVText/Text'
import TreeComponent from './PrivateComponent/TreeComponent'
import { Icon } from '../../../services/icon'
import { LightNode, NodeData } from './types'
import { StateType } from '../../../mvfunctions/hooks/useGenericInputHook'
import { StateValidation } from '../../../mvfunctions/stateValidation'
import { areSameTree } from './function'
import { useComponentsTranslation } from '../../../services/translation'
import { useDidUpdate, usePrevious } from '../../../mvfunctions/hooks/hooksHelper'
import { Changeable as Changeable } from '../../../mvtypes/onFunction'

type Selections = 'MultiSelect' | 'SingleSelect'
type Flows = 'Down' | 'Up' | 'Solo'
export type TreeMultiSelectFlow = 'Down' | 'Up'
export type TreeSingleSelectFlow = Flows

interface GeneralProps extends StateValidation {
  id?: string
  data: NodeData
  selection: Selections
  flow: Flows
  title?: string
  required?: boolean
  readonly?: boolean
  scrollable?: boolean
}

export interface TreeMultiSelectProps extends GeneralProps, Changeable<[ids: LightNode[]]> {
  selection: 'MultiSelect'
  flow: TreeMultiSelectFlow
  initialSelected?: Array<LightNode | string>
}

export interface TreeSingleSelectProps extends GeneralProps, Changeable<[id?: LightNode]> {
  selection: 'SingleSelect'
  flow: TreeSingleSelectFlow
  initialSelected?: LightNode | string
}

type Props = (TreeMultiSelectProps | TreeSingleSelectProps) & IFlexItem

const Tree = (props: Props) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { t } = useComponentsTranslation()

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { data, selection, flow, title, required, scrollable } = props

  const base = 'components.tree'
  const baseT = 'mv-treeview-container'

  const [numberOfSelections, setNumberOfSelections] = useState(0)
  const [state, setState] = useState<StateType>({ kind: 'notValid', message: t(`${base}.errors.noElements`) })
  const [showAlert, setShowAlert] = useState(false)
  const [treeKey, setTreeKey] = useState(true)
  const [isChangedTreeKey,setIsChangedTreeKey] = useState(false)
  const prevData = usePrevious(props.data)

  const selectionIcons: { [key in Selections]: { icon: Icon; message: string } } = {
    MultiSelect: { icon: 'check-double', message: `${base}.tooltip.multipleSelection` },
    SingleSelect: { icon: 'check', message: `${base}.tooltip.singleSelection` },
  }
  const flowIcons: { [key in Flows]: { icon: Icon; message: string } | undefined } = {
    Down: { icon: 'arrow-down', message: `${base}.tooltip.downPropagation` },
    Up: { icon: 'arrow-up', message: `${base}.tooltip.upPropagation` },
    Solo: undefined,
  }
  const iconSelection = selectionIcons[selection]
  const iconFlow = flowIcons[flow]

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
      props.onChangeState?.(state)
  }, [state])

  useEffect(() => {
    return () => {
      props.onChangeState?.({kind:'valid'})
    }
  }, [])
  

  // used to recreate the tree if the tree structure is changed.
  // setIsChangedTreeKey is used to prevent an useless onChange on Tree on the first did mount
  useDidUpdate(() => {
    if (!areSameTree(prevData, props.data)) {
      setTreeKey(prev => !prev)
      setIsChangedTreeKey(true)
    }
  }, [props.data])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const handleChangeMultiSelect = (nodes: LightNode[]) => {
    setNumberOfSelections(nodes.length)
    manageRequiredTree(nodes)
    if (props.selection === 'MultiSelect' && props.onChange) {
      props.onChange(nodes)
    }
  }

  const handleChangeSingleSelect = (node: LightNode | undefined) => {
    manageRequiredTree(node)
    if (props.selection === 'SingleSelect' && props.onChange) {
      props.onChange(node)
    }
  }

  const manageRequiredTree = (nodes: LightNode[] | LightNode | undefined) => {
    if (props.required) {
      setShowAlert(true)
      if (!nodes || (Array.isArray(nodes) && nodes.length <= 0)) {
        setState({ kind: 'notValid', message: t(`${base}.errors.noElements`) })
      } else {
        setState({ kind: 'valid' })
      }
    }
  }

  const areSomeInfoToShow = (): boolean => {
    return selection === 'MultiSelect' || title !== undefined || title !== ''
  }

  const setDisableToAllNode = (node: NodeData): NodeData => {
    return {
      label: node.label,
      id: node.id,
      disabled: true,
      children: node.children && node.children.length > 0 ? node.children?.map(c => setDisableToAllNode(c)) : undefined,
    }
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <StretchColumn
      key={treeKey.toString()}
      className={`${baseT} ${renderFlexItem(props as IFlexItem)}`}
      alignSelf={AlignSelf.stretch}
    >
      <Flex
        className={`${baseT}__info`}
        justifyContent={areSomeInfoToShow() ? JustifyContent.between : JustifyContent.end}
        alignItems={AlignItems.start}
        alignContent={AlignContent.stretch}
      >
        {areSomeInfoToShow() && (
          <Flex direction={Direction.column} alignItems={AlignItems.start}>
            {title && <Text text={`|**${title}${required ? '* ' : ''}**|`} />}
            {(selection === 'MultiSelect' && (
              <Text
                text={t(`${base}.${numberOfSelections === 1 ? 'selectedElement' : 'selectedElements'}`, {
                  value: numberOfSelections.toString(),
                })}
                semantic={'light'}
              />
            )) ||
              null}
          </Flex>
        )}

        <Flex spaceSize={'sm'}>
          <FlexItem
            tooltip={{ content: t(iconSelection.message), disableIcon: true, position: 'left' }}
            className={`${baseT}__info-icon-selection`}
          >
            <IconComponent icon={iconSelection.icon} />
          </FlexItem>

          {iconFlow && (
            <FlexItem
              tooltip={{ content: t(iconFlow.message), disableIcon: true, position: 'left' }}
              className={`${baseT}__info-icon-flow`}
            >
              <IconComponent icon={iconFlow.icon} />
            </FlexItem>
          )}
        </Flex>
      </Flex>
      <TreeFactory
        data-testid={"tree"}
        isNewDidMount={isChangedTreeKey}
        data={props.readonly ? setDisableToAllNode(data) : data}
        flow={flow}
        selection={selection}
        scrollable={scrollable}
        onChangeSelections={handleChangeMultiSelect}
        onChangeSelection={handleChangeSingleSelect}
        initialSelected={
          props.initialSelected
            ? props.selection === 'SingleSelect'
              ? [props.initialSelected]
              : props.initialSelected
            : undefined
        }
      />
      {showAlert && state.kind === 'notValid' ? (
        <Alert variant={'warning'} text={state.message} />
      ) : props.showError && props.error ? (
        <Alert variant={'warning'} text={props.error} />
      ) : null}
    </StretchColumn>
  )
}

interface TreeCreatorProps {
  data: NodeData
  selection: Selections
  flow: Flows
  onChangeSelections: (ids: LightNode[]) => void
  onChangeSelection: (id?: LightNode) => void
  initialSelected?: Array<LightNode | string>
  scrollable?: boolean
  isNewDidMount: boolean
}

// Since TreeFactory create new object on init is important to don't rerun the component if not necessary.
// Since TreeWrapper has a setState for the info update is necessary to move the creation of the Tree in an isolate component
const TreeFactory = (props: TreeCreatorProps) => {
  const { selection, flow, data, onChangeSelections, initialSelected } = props

  const handleChangeSelection = (ids: LightNode[]) => {
    if (ids.length > 0) {
      props.onChangeSelection(ids[0])
    } else {
      props.onChangeSelection(undefined)
    }
  }

  const createSelectionLogic = (): SelectionLogic => {
    switch (selection) {
      case 'MultiSelect':
        return new MultiSelectUpLogic(data, initialSelected)
      case 'SingleSelect':
        switch (flow) {
          case 'Down':
            return new SingleSelectDownLogic(data, initialSelected)
          case 'Solo':
            return new SingleNodeLogic(data, initialSelected)
        }
    }
    // just for default case
    return new SingleNodeLogic(data, initialSelected)
  }

  const [selectionLogic] = useState(createSelectionLogic())

  switch (selection) {
    case 'MultiSelect':
      switch (flow) {
        case 'Down':
          return <MultiselectDown {...props} onChange={onChangeSelections} />
        case 'Up':
          return <TreeComponent {...props} selectionLogic={selectionLogic} onChange={onChangeSelections} />
        case 'Solo':
          console.warn('Tree SingleSelect Up not implemented') //tslint:disable-line
          return <>Not implemented</>
        default:
          console.warn('Default case MultiSelect') //tslint:disable-line
          return <>Not implemented</>
      }
    case 'SingleSelect':
      switch (flow) {
        case 'Down':
          return <TreeComponent {...props} selectionLogic={selectionLogic} onChange={handleChangeSelection} />
        case 'Up':
          console.warn('Tree SingleSelect Up not implemented') //tslint:disable-line
          return <>Not implemented</>
        case 'Solo':
          return <TreeComponent {...props} selectionLogic={selectionLogic} onChange={handleChangeSelection} />
      }
  }
}

export default Tree
