// * -------------------------------- NPM --------------------------------------
import React, { useEffect, useRef, useState } from 'react'

/**
 * At the moment there isn't a state for a function component that represent only the didUpdate of a React.Component. This hook manage this particular state.
 * @param callback Function to execute on DidUpdate
 * @param deps Dependencies that fire the didUpdate
 */
export function useDidUpdate(callback: () => void, deps?: React.DependencyList | undefined) {
  const hasMount = useRef(false)

  useEffect(() => {
    if (hasMount.current) {
      callback()
    } else {
      hasMount.current = true
    }
  }, deps)
}


export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  return hasMounted
}

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
