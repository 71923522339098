// * -------------------------------- NPM --------------------------------------
import React, { useState, useEffect } from 'react'

// * -------------------------------- MODULE --------------------------------------
import DateComponent from './PrivateComponents/DateComponent'
import DiscreteTimeComponent from './PrivateComponents/DiscreteTimeComponent'
import IconComponent from '../MVIcon/Icon'
import InputComponent from './PrivateComponents/InputComponents'
import SelectComponent from './PrivateComponents/SelectComponent'
import Text from './../MVText/Text'
import {
  Components,
  IContinueTimeComponents,
  IDiscreteTimeComponents,
  IInputComponent,
  IIntervalDateComponent,
  IMultiSelectComponent,
  ISingleDateComponent,
  ISingleSelectComponent,
  OtherInputProps,
} from '../MVInput/types'
import { IFlexItem, renderFlexItem } from '../../../ui/components/MVFlex/FlexItem'
import { IconProps } from '../../../mvtypes/base'
import { StateType } from '../../../mvfunctions/hooks/useGenericInputHook'

/*
 * Inputs:
 * (kind)
 *   - Input
 *      (type)
 *      * text
 *      * number
 *      * password
 *
 *   - Select
 *      (type)
 *      * single-select
 *      * multi-select
 *
 *   - Date
 *
 *   - Time
 *      (type)
 *      * discrete
 *      * continue
 *
 * If you want to use Input as Filter use FilterInput
 */
type OtherProps = IconProps & IFlexItem
export type Props = Components & OtherProps
const Input = (props: Props) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const mvFG = 'mv-form-group'
  const baseInputName = 'mv-input-group'

  const { label, icon, success, showError, disabled, isDisable, info, id, error } = props

  const disable = disabled || isDisable
  const [internalError, setInternalError] = useState<string>('')
  const [showErrorOnBlur, setShowErrorOnBlur] = useState<boolean>(false)

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const conditionShowError = () => {
    return (showError !== undefined ? showError : showErrorOnBlur) && (error || internalError)
  }

  const stringValidity = (): string =>
    success ? 'is-valid' : conditionShowError() ? 'is-invalid' : info ? 'is-info' : ''

  const onChangeState = (state: StateType) => {
    if (state.kind === 'notValid') {
      setInternalError(state.message)
    } else {
      setInternalError('')
    }
    if (props.onChangeState) {
      props.onChangeState(state)
    }
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENTS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    // on on-mount fire a valid input state
    // TODO spostare nell'hook ?
    return () => {
      props.onChangeState?.({ kind: 'valid' })
    }
  }, [])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const renderValidity = () => {
    if (props.hideInfoFeedback) {
      return
    }
    let feed = 'info-feedback'
    let text = info || 'never visible'
    if (success) {
      feed = 'valid-feedback'
      text = success
    }

    if (conditionShowError()) {
      feed = 'invalid-feedback'
      text = error || internalError
    }

    return (
      <div data-testid={'input-feedback'} className={feed}>
        <Text text={text} />
      </div>
    )
  }

  const renderInput = () => {
    return <InputFactory {...props} onChangeState={onChangeState} validity={stringValidity()} />
  }

  return (
    <div
      className={`${mvFG} ${disable ? `${mvFG}--disabled` : ''} ${renderFlexItem({ ...(props as IFlexItem) })}`}
      key={id}
      onBlur={() => {
        setShowErrorOnBlur(true)
        if (props.onBlur) {
          props.onBlur()
        }
      }}
    >
      {(label || props.required) && (
        <label htmlFor={label}>
          {label}
          {props.required && <abbr className="required"> *</abbr>}
        </label>
      )}
      {(icon && (
        <div className={`${baseInputName}`}>
          <div className={`${baseInputName}__prepend ${stringValidity()}`}>
            <span className={`${baseInputName}__icon`}>
              <IconComponent icon={icon} />
            </span>
          </div>
          {renderInput()}
          {renderValidity()}
        </div>
      )) || (
        <>
          {renderInput()}
          {renderValidity()}
        </>
      )}
    </div>
  )
}

const InputFactory = (props: Props & OtherInputProps) => {
  switch (props.kind) {
    case 'date':
      return <DateComponent {...props} />
    case 'input':
      return <InputComponent {...props} />
    case 'select':
      return <SelectComponent {...props} />
    case 'time':
      switch (props.timeType) {
        case 'continue':
          return <InputComponent {...props} type={'time'} />
        case 'discrete':
          return <DiscreteTimeComponent {...props} />
      }
  }
}

// * ------------------------------------------
// * Helpers Component that pre-fill some props
// * ------------------------------------------

// * ------------------ Text-Area
export const TextArea = (props: Omit<IInputComponent, 'kind'> & OtherProps) => (
  <Input rows={3} {...props} kind={'input'} />
)

// * ------------------ Password
export const InputPassword = (props: Omit<IInputComponent, 'kind' | 'type'> & OtherProps) => (
  <Input {...props} kind={'input'} type={'password'} />
)

// * ------------------ Text
export const InputText = (props: Omit<IInputComponent, 'kind' | 'type'> & OtherProps) => (
  <Input {...props} kind={'input'} type={'text'} />
)

// * ------------------ Select
export const Select = <T extends string>(props: Omit<ISingleSelectComponent<T>, 'kind' | 'type'> & OtherProps) => (
  <Input {...props} kind={'select'} type={'singleSelect'} onChange={props.onChange as any} />
)

// * ------------------ MultiSelect
export const MultiSelect = <T extends string>(props: Omit<IMultiSelectComponent<T>, 'kind' | 'type'> & OtherProps) => (
  <Input {...props} kind={'select'} type={'multiSelect'} onChange={props.onChange as any} />
)

// * ------------------ Date
export const InputDate = (props: Omit<ISingleDateComponent, 'kind' | 'type'> & OtherProps) => (
  <Input {...props} kind={'date'} type={'singleDate'} />
)

// * ------------------ Date Interval
export const InputDateInterval = (props: Omit<IIntervalDateComponent, 'kind' | 'type'> & OtherProps) => (
  <Input {...props} kind={'date'} type={'dateInterval'} />
)

// * ------------------ Number
export const InputNumber = (props: Omit<IInputComponent, 'kind'> & OtherProps & OtherProps) => (
  <Input {...props} kind={'input'} type={'number'} />
)

// * ------------------ Number Integer
export const InputIntegerNumber = (props: Omit<IInputComponent, 'kind'> & OtherProps) => (
  <Input {...props} kind={'input'} type={'integer'} />
)

// * ------------------ Discrete Time
export const InputDiscreteTime = (props: Omit<IDiscreteTimeComponents, 'kind' | 'timeType'> & OtherProps) => (
  <Input {...props} kind={'time'} timeType={'discrete'} />
)

// * ------------------ Continue Time
export const InputContinueTime = (props: Omit<IContinueTimeComponents, 'kind' | 'timeType'> & OtherProps) => (
  <Input {...props} kind={'time'} timeType={'continue'} />
)

export default Input
