// * -------------------------------- NPM --------------------------------------
import React, { useEffect, useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Node, { SelectionTypes } from './Node'
import { LightNode, NodeData, ProcessedNodeData } from '../types'
import { getAllChildren, getNodeFromString, isNodeInTree, removeItemFrom } from '../function'
import { useDidUpdate } from '../../../../mvfunctions/hooks/hooksHelper'

interface TreeProps {
  data: NodeData
  initialSelected?: Array<LightNode | string>
  onChange?: (ids: LightNode[]) => void
  isNewDidMount: boolean
  scrollable?: boolean
}

type Id = string

const MultiselectDown = ({ initialSelected = [], ...props }: TreeProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { data, scrollable } = props
  const [listOpened, setListOpened] = useState<LightNode[]>([{ id: props.data.id, label: props.data.label }])
  const [listSelected, setListSelected] = useState<Record<Id, LightNode>>(setInitialSelected())

  const t = 'mv-treeview'

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    if (Object.entries(listSelected).length > 0 || props.isNewDidMount) {
      props.onChange?.(Object.values(listSelected))
    }
  }, [])

  useDidUpdate(() => {
    if (props.onChange) {
      props.onChange(Object.values(listSelected))
    }
  }, [listSelected])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  function setInitialSelected() {
    return initialSelected.reduce((acc: Record<Id, LightNode>, curr) => {
      if (typeof curr === 'string') {
        const node = getNodeFromString(data, curr)
        if (node) {
          return { ...acc, [node.id]: node }
        }
      } else {
        const node = isNodeInTree(data, curr)
        if (node) {
          return { ...acc, [curr.id]: curr }
        }
      }
      return acc
    }, {})
  }

  const handleSelectionNode = (node: ProcessedNodeData, state: boolean, selection: SelectionTypes) => {
    if (state) {
      let children: Record<Id, LightNode> = {}
      let me: Record<Id, LightNode> = {}

      if (selection === 'OnlyChildren' || selection === 'NodeAndChildren') {
        children = getAllChildren(node)
      }
      if (selection === 'Node' || selection === 'NodeAndChildren') {
        me = { [node.id]: { id: node.id, label: node.label } }
      }

      setListSelected(prev => {
        return { ...prev, ...children, ...me }
      })
    } else {
      setListSelected(prev => {
        const newList = { ...prev }

        if (selection === 'Node' || selection === 'NodeAndChildren') {
          delete newList[node.id]
        }
        if (selection === 'OnlyChildren' || selection === 'NodeAndChildren') {
          const children = getAllChildren(node)
          Object.values(children).forEach(p => {
            delete newList[p.id]
          })
        }

        return newList
      })
    }
  }

  const handleToggleSelection = (node: ProcessedNodeData, state: boolean) => {
    if (state) {
      setListOpened(prev => [...prev, { id: node.id, label: node.label }])
    } else {
      setListOpened(prev => {
        let newList = [...prev]
        newList = removeItemFrom(newList, node)
        return newList
      })
    }
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <div className={`${t} ${scrollable ? `${t}--scrollable` : ''}`}>
      <ul className={`${t}__rootlist`}>
        <Node
          isRoot={true}
          node={{ ...data, parent: undefined }}
          onSelectionNode={handleSelectionNode}
          onToggle={handleToggleSelection}
          listOpened={listOpened}
          listSelected={listSelected}
        />
      </ul>
    </div>
  )
}

export default React.memo(MultiselectDown)
