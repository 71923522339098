// * -------------------------------- NPM --------------------------------------
import React, { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Button, { ButtonProps } from './Button'
import Flex, { Direction } from '../MVFlex/Flex'
import Text from '../MVText/Text'

interface ConfirmProps {
  labelConfirm: string
  buttonConfirm: ButtonProps
}

export type ButtonWithConfirmProps = ButtonProps & ConfirmProps
const ButtonWithConfirm = (props: ButtonWithConfirmProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const [confirm, setConfirm] = useState<boolean>(false)

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <Flex direction={Direction.row}>
      {confirm ? (
        <>
          <Text text={props.labelConfirm} />
          <Button
            {...(props.buttonConfirm as ButtonProps)}
            onClick={e => {
              if (props.buttonConfirm.onClick) {
                props.buttonConfirm.onClick(e)
              }
              setConfirm(false)
            }}
          />
        </>
      ) : (
        <Button
          {...(props as ButtonProps)}
          onClick={e => {
            if (props.onClick) {
              props.onClick(e)
            }
            setConfirm(true)
          }}
        />
      )}
    </Flex>
  )
}

export default ButtonWithConfirm
