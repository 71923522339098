const es = {
  tutelage: {
    list: {
      target: 'Máquinas',
    },
    taskCreation: {
      target: {
        title: 'Máquinas',
        asset: {
          label: 'máquinas',
          radio: 'Máquinas específicas',
          sel: 'máquina seleccionada',
          sels: 'máquinas seleccionadas',
          found: '{{value}} de {{totalValue}} máquinas encontradas {{valueSpecific}}'
        },
        group: {
          radio: 'Grupos de máquinas',
          sel: 'Grupo de máquinas seleccionado',
          sels: 'Grupos de máquinas seleccionados',
          found: '{{value}} de {{totalValue}} grupos de máquinas encontrados {{valueSpecific}}'
        },
        checkbox: {
          label: 'Aplicar {{value}} a las máquinas',
        },
        radio: {
          specific: 'Crear {{value}} específico para cada máquina involucrada',
          unique: 'Crear un solo {{value}} para todas las máquinas involucradas',
        },
      },
    },
    detail: {
      target: 'Máquinas',
    },
  },
}

export default es
