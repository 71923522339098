// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Flex, { JustifyContent } from '../MVFlex/Flex'
import Text, { HyperTextProps } from '../MVText/Text'
import { IFlexItem, renderFlexItem } from '../MVFlex/FlexItem'

export type AlertVariants = 'info' | 'danger' | 'warning'

interface Props extends HyperTextProps, IFlexItem {
  variant: AlertVariants
  actions?: React.ReactNode
}

const Alert = (props: Props) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const al = 'alert'
  const { variant, actions } = props

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------

  return (
    <Flex
      justifyContent={JustifyContent.between}
      className={`${al} ${al}-${variant} ${renderFlexItem({ ...(props as IFlexItem) })}`}
    >
      <Text {...(props as HyperTextProps)} />
      {actions && <Flex>{actions}</Flex>}
    </Flex>
  )
}

export default Alert
