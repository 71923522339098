// * -------------------------------- MODULE -----------------------------------
import { StoreProps } from '../../types/table'
import { SET_COLUMN_SIZE, SET_FILTERS, SET_PAGINATION, TableAction } from '../actions'

export type TableStore = StoreProps

const initialState: TableStore = {
  pagination: {},
  listFilters: {},
  columnSize: {},
}

export const tableReducer = (state: TableStore = initialState, action: TableAction) => {
  switch (action.type) {
    case SET_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.params,
        },
      }
    case SET_FILTERS:
      return {
        ...state,
        listFilters: {
          ...state.listFilters,
          ...action.params,
        },
      }
    case SET_COLUMN_SIZE:
      return {
        ...state,
        columnSize: {
          ...state.columnSize,
          ...action.params,
        },
      }
    default:
      return state
  }
}

export default tableReducer
