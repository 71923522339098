// * -------------------------------- MODULE -----------------------------------
import { ListColumnSize } from '../types/table'

type Callback = (newSizes: { [colId: string]: string }) => void

interface ICreateResizeColumn {
  tableId: string
  columnSize: ListColumnSize
  callback: Callback
}

const createResizeColumn = (params: ICreateResizeColumn) => {
  const { tableId, columnSize, callback } = params

  const table = document.getElementById(tableId)

  const cols = (table?.querySelectorAll('th.can-resize') as any) as HTMLTableCellElement[]

  cols?.forEach(col => {
    const resizer = document.createElement('div')
    resizer.classList.add('resizer')

    if (columnSize[col.id]) {
      col.style.width = columnSize[col.id]
    }

    // Set the height
    resizer.style.height = `${table?.offsetHeight}px`

    // Add a resizer element to the column
    col.appendChild(resizer)

    createResizableColumn(table, col, resizer, callback)
  })
}

const createResizableColumn = (
  table: HTMLElement | null,
  col: HTMLTableCellElement,
  resizer: HTMLDivElement,
  callback: Callback
) => {
  // Track the current position of mouse
  let x = 0
  let w = 0
  let dx = 0

  const mouseEnterHandler = (_e: MouseEvent) => {
    col.style.clear = 'both'
    resizer.style.height = `${table?.offsetHeight}px`
  }

  const mouseDownHandler = (e: MouseEvent) => {
    // Get the current mouse position
    x = e.clientX

    // Calculate the current width of column
    const styles = window.getComputedStyle(col)
    w = parseInt(styles.width, 10)

    // Attach listeners for document's events
    document.addEventListener('mousemove', mouseMoveHandler)
    document.addEventListener('mouseup', mouseUpHandler)
    resizer.classList.add('resizing')
  }

  const mouseMoveHandler = (e: MouseEvent) => {
    // Determine how far the mouse has been moved
    dx = e.clientX - x

    // Update the width of column
    col.style.width = `${w + dx}px`
    resizer.style.height = `${table?.offsetHeight}px`
  }

  // When user releases the mouse, remove the existing event listeners
  const mouseUpHandler = () => {
    const newSize = { [col.id]: col.style.width }

    callback(newSize)

    document.removeEventListener('mousemove', mouseMoveHandler)
    document.removeEventListener('mouseup', mouseUpHandler)
    resizer.classList.remove('resizing')
  }

  resizer.addEventListener('mouseenter', mouseEnterHandler)
  resizer.addEventListener('mousedown', mouseDownHandler)
}

export default createResizeColumn