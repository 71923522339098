// follow: https://docs.google.com/spreadsheets/d/1sybUDTHLbs6xLvyQyqVy3WESWYFdIH_8Go4D0b6Dwes
enum URLMapping {
  'iot.blupura.com' = 'blupura',
  'blupura-test.iotdtindustry.com' = 'blupura',
  'localhost:3000' = 'artide',
  'dcloud.dtindustry.com' = 'dtif',
  'dcloud-test.iotdtindustry.com' = 'dtdtifi',
  'www-test.dti.vidim.it' = 'dtif',
}

export const getSlugFromURL = (): string => {
  const host = window.location.host
  return URLMapping[host] || ''
}

export default getSlugFromURL
