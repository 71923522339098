import cn from './locales/cn.json'
import de from './locales/de.json'
import en from './locales/en.json'
import es from './locales/es.json'
import fr from './locales/fr.json'
import it from './locales/it.json'
import pl from './locales/pl.json'
import ru from './locales/ru.json'

export default {
  cn,
  de,
  en,
  es,
  fr,
  it,
  pl,
  ru,
}
