// * -------------------------------- MODULE --------------------------------------
import { IconComponentService, IconComponentServiceProps } from '../../../services/icon'
import { TYPES } from '../../../services/binding'
import { useComponent } from '../../../services/component'

const IconComponent = ({ className = '', ...props }: IconComponentServiceProps) => {
  const component = useComponent<IconComponentService>(TYPES.IconComponentService)
  const { semantic, ...iconServiceProps } = props
  const mvIcon = 'mv-icon'
  return (
    component?.render({
      ...iconServiceProps,
      className: `${mvIcon} ${semantic ? `${mvIcon}--${semantic}` : ''} ${
        iconServiceProps.onClick ? `${mvIcon}--clickable` : ''
      } ${className}`,
    }) || null
  )
}

export default IconComponent
