// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Input from '../MVInput/Input'
import {IDateComponent, IInputComponent, InputTypes, ISelectComponent} from '../MVInput/types'
import {IFlexItem} from '../MVFlex/FlexItem'
import {IconName} from '../../../services/icon'

export type Props = IInputComponent | ISelectComponent | IDateComponent

/*
  * A simple wrapper of Input
  ! Use FilterInput when you want to use Input as Filter
*/

const FilterInput = (props: Props & IFlexItem) => {
	// * ----------------------------------------------------------------------------------------
	// * -------------------------------- INIT --------------------------------------
	// * ----------------------------------------------------------------------------------------
	const inputIcons: { [key in InputTypes]: IconName } = {
		input: 'search',
		date: 'calendar',
		select: 'filter',
	}

	// * ----------------------------------------------------------------------------------------
	// * -------------------------------- RENDERs --------------------------------------
	// * ----------------------------------------------------------------------------------------
	return <Input hideInfoFeedback={true} {...props} icon={inputIcons[props.kind]}/>
}

export default FilterInput
