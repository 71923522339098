// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { detect } from 'detect-browser'
import { BrowserRouter as Router } from 'react-router-dom'

// * -------------------------------- COMPONENTS --------------------------------------
import { RouteProps } from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/SideBar/SideBar'
import Navigation from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/Navigation'
import Main from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/Main'
import CenterLoader from '@mv-submodules/inplant-components-fe/ui/components/MVLoaders/CenterLoader'
import { useComponentsTranslation } from '@mv-submodules/inplant-components-fe/services/translation'

// * -------------------------------- CONFIG --------------------------------------
import { aclRedirect, aclRoutes } from '@mv-submodules/inplant-config/config/routes'

// * -------------------------------- MODULE --------------------------------------
import TopBarCore from './TopBar'
import { Login, PrivateRoute, User, Webview, webviewRoute } from '@mv-submodules/inplant-core-fe/auth'
import { useAppSelector } from '@mv-submodules/inplant-core-fe/redux/reducers'
import useBranding from '@mv-submodules/inplant-core-fe/functions/useBranding'
import useRouting from '@mv-submodules/inplant-core-fe/functions/useRouting'
import Assets from '@mv-submodules/inplant-core-fe/types/assets'
import VersionChecker from '../VersionChecker/components/VersionChecker'
import SideBarBottomWidget from '@mv-submodules/inplant-core-fe/ui/components/widgets/SidebarBottomWidget/SidebarBottomWidget'
import { useCounters } from '@mv-submodules/inplant-core-fe/ui/components/widgets/Counters/Counters'
import InitProvider from '../Provider/InitProviders'
import { useBreadcrumbsTitle } from '../BreadcrumbsTitle/BreadcrumbsTitle'
import { toggleCollapseMenu } from '@mv-submodules/inplant-core-fe/redux/actions/settings'
import { useDispatch } from 'react-redux'
import ReactNotification from '@mv-submodules/inplant-components-fe/ui/components/MVNotification/Notification'
import { useEffect } from 'react'
import { tokenAlive } from '@mv-submodules/inplant-core-fe/redux/actions/auth'

const browser = detect()

const Layout = () => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { t } = useComponentsTranslation()
  const brandingHook = useBranding()
  const { routes, sidebarRoutes, loginSuccessPath, externalLinkRoutes } = useRouting()
  const { counters } = useCounters()
  const { segmentsName } = useBreadcrumbsTitle()
  const { isMenuCollapsed } = useAppSelector(state => state.core.settingsManager)
  const useNewLogin: boolean = useAppSelector((state: any) => state.config.generic.useNewLogin || false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (useNewLogin) {
      tokenAlive()(dispatch)
    }
  }, [])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const loginSuccess: boolean = useAppSelector(state => state.auth.loggedIn)
  const user: User | null = useAppSelector(state => state.auth.user)

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  function getComposeRoutes(moduleRoutes: RouteProps[]): React.ReactNode {
    return moduleRoutes.map(route => {
      if (!route.children) {
        return <PrivateRoute key={route.path} exact={route.exact} path={route.path} component={route.component} />
      }
      return getComposeRoutes(route.children)
    })
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  function render() {
    switch (brandingHook.kind) {
      case 'fetching':
        return (
          <div>
            <CenterLoader />
          </div>
        )
      case 'success':
        return renderSuccess(brandingHook.assets)
    }
  }

  function renderSuccess(assets: Assets) {
    if (browser && browser.name !== 'ie') {
      return (
        <Router>
          <TopBarCore routes={routes} logo={assets.logoTopBar} />
          <ReactNotification className={'notifications-component--core'} />
          <Navigation
            navigationVisibility={loginSuccess}
            breadcrumbs={{ routes, segmentsName }}
            sidebar={{
              routes: sidebarRoutes,
              counters,
              collapsed: isMenuCollapsed,
              onToggleCollapsedMenu: isCollapsed => toggleCollapseMenu(isCollapsed)(dispatch),
              otherComponents: <VersionChecker />,
            }}
          >
            <Main
              pages={getComposeRoutes(aclRoutes(routes, user?.forbiddenActions || [], loginSuccessPath))}
              otherPages={[{ component: Webview, path: webviewRoute.path }]}
              redirectURLs={externalLinkRoutes.map(l => l.path)}
            >
              <Login successPath={aclRedirect(loginSuccessPath)} image={assets.logoLogin} />
            </Main>
          </Navigation>
          {loginSuccess && <SideBarBottomWidget />}
        </Router>
      )
    }
    return obsoleteRender()

    function obsoleteRender() {
      return (
        <div className="container">
          <div className="alert alert-warning mt-5" role="alert">
            <h4 className="alert-heading">{t('ieDetected.title')}</h4>
            <p>{t('ieDetected.subTitle')}</p>
            <hr />
            <p className="mb-0">
              {t('ieDetected.description')}{' '}
              <a href="https://www.mozilla.org/it/firefox/new/" target="_blank">
                Mozilla Firefox
              </a>{' '}
              {t('ieDetected.or')}{' '}
              <a href="https://www.google.com/intl/it/chrome/" target="_blank">
                Google Chrome
              </a>
              .
            </p>
          </div>
        </div>
      )
    }
  }

  return render()
}

export default InitProvider(Layout)
