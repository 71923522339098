// * -------------------------------- NPM --------------------------------------
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Button from '../../MVButtons/Button'
import Flex from '../../MVFlex/Flex'
import SideBarItem, { RouteCounter, SidebarCounter } from './SideBarItem'
import { ButtonVariants } from '../../MVButtons/types'
import { Icon } from '../../../../services/icon'
import { useComponentsTranslation } from '../../../../services/translation'

/**
 * @param i18nkey: slug used for translation
 * @param i18nOptions: an object that define the possible options of the translation, specific `needTranslation` if the option is not a string but a key used for another translation
 *
 * @param external: specific if the route is used for an external link
 * @param requiredAuth: specify if the external link required token for auth
 *
 * @param counter: used if the page can have a counter (notification)
 *
 * @param sortOrder: to sort the routes
 *
 * @param iconName: used on external routes to set an icon
 *
 * @param children: define the sub-routes for a module.
 *
 * @param breadcrumbPath: only the part of the path created for that component. It is used in the breadcrumb component to show pretty breadcrumbs name
 *
 * @param counter: define the props used to set and display the counter on navigation
 */
export interface RouteProps {
  path: string
  exact?: boolean
  hiddenMobile?: boolean
  i18nkey: string
  i18nOptions?: { [key: string]: { label: string; needTranslation?: boolean } }
  icon?: Icon
  iconOverride?: string
  visible: boolean
  visibleInUserProfile?: boolean
  component?: React.ComponentType<any>
  aclActionKey?: string
  external?: boolean
  sortOrder?: number
  requiredAuth?: { required: false } | { required: true; token: string }
  counter?: RouteCounter
  breadcrumbPath?: string
  children?: RouteProps[]
}

/**
 * Declare the interface used for create an external route
 * env variable used in core
 *
 * @param i18nkey key for translation
 * @param sortOrder to specify the order in the sidebar menu
 * @param pathname pathname of url
 * @param origin url origin, if undefined is used the local origin
 * @param acl if not null must be satisfied
 */
export interface ExternalRouteProps {
  i18nkey: string
  pathname: string
  origin?: string
  requiredAuth?: boolean
  iconName?: string
  sortOrder?: number
  acls?: string[]
}

export const translateRouteKey = (translation: { t: Function }, route: RouteProps) => {
  const { t } = translation
  return t(
    route.i18nkey,
    (route.i18nOptions &&
      Object.keys(route.i18nOptions).reduce((res: { [key: string]: any }, key) => {
        const option = route.i18nOptions![key]
        res[key] = option.needTranslation ? t(option.label) : option.label
        return res
      }, {})) ||
      ''
  )
}

export interface SideBarProps {
  collapsed?: boolean
  routes: RouteProps[]
  counters?: { [key: string]: SidebarCounter }
  otherComponents?: JSX.Element | React.Component
  onToggleCollapsedMenu?: (isCollapsed: boolean) => void
}

interface HiddenProps {
  onClickMobileMenu?: () => void
  visible: boolean
}

const SideBar = (props: SideBarProps & HiddenProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { t } = useComponentsTranslation()
  const location = useLocation()

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const mvs = 'mv-sidebar'
  const trad = 'components.layout.sidebar'

  const [itemsOpen, setItemsOpen] = useState<{ [index: number]: boolean }>({})
  const [itemActive, setItemActive] = useState(0)
  const [collapseMenu, setCollapseMenu] = useState<boolean>(props.collapsed ?? false)

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    const indexModule = props.routes.findIndex(route => {
      return (
        location.pathname.includes(route.path) ||
        route.children?.reduce((acc: boolean, curr) => location.pathname.includes(curr.path) || acc, false)
      )
    })

    setItemActive(indexModule)
    if (!collapseMenu) {
      setItemsOpen(prev => ({
        ...prev,
        [indexModule]: true,
      }))
    }
    if (collapseMenu) {
      setItemsOpen({})
    }
  }, [location, props.routes])

  const handleClickSidebarItem = (index: number) => {
    setItemsOpen(prev => ({
      ...(!collapseMenu ? prev : {}),
      [index]: prev[index] !== undefined ? !prev[index] : true,
    }))
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <nav className={`${mvs} ${props.visible ? `${mvs}--open` : ''} ${collapseMenu ? `${mvs}--collapsed` : ''}`}>
      <ul className={`${mvs}__menu`}>
        <li className={`${mvs}__close`}>
          <Button
            icon={'times'}
            semantic={'secondary'}
            variant={ButtonVariants.flat}
            onClick={() => {
              props.onClickMobileMenu?.()
            }}
          />
        </li>
        {props.routes
          .filter(r => r.visible)
          .map((route: RouteProps, idx: number) => (
            <SideBarItem
              active={idx === itemActive}
              onClickItem={() => handleClickSidebarItem(idx)}
              collapsed={collapseMenu}
              baseClassName={mvs}
              key={`route-${idx}`}
              open={itemsOpen[idx]}
              sidebarCounters={props.counters}
              {...route}
            />
          ))}
      </ul>
      {(props.otherComponents && <div className={`${mvs}__infos`}>{props.otherComponents}</div>) || null}
      <Flex className={`${mvs}__closing-button ${mvs}__closing-button-animated`}>
        <Button
          icon={collapseMenu ? 'chevron-right' : 'chevron-left'}
          semantic="secondary"
          label={collapseMenu ? '' : t(`${trad}.onlyIcon`)}
          variant={ButtonVariants.flat}
          grow={1}
          onClick={() => {
            setCollapseMenu(prev => {
              const newValue = !prev
              if (newValue) {
                setItemsOpen({})
              }
              if (!newValue) {
                setItemsOpen({ [itemActive]: true })
              }
              props.onToggleCollapsedMenu?.(newValue)
              return newValue
            })
          }}
        />
      </Flex>
    </nav>
  )
}

export default SideBar
