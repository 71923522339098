import { i18next as i18n, i18nextInit } from '@mv-submodules/inplant-core-fe/i18n/i18n'
import overwrites from '../i18n/overwrite'
import {extend} from '@mv-submodules/inplant-core-fe/functions/objects'
import core from '@mv-submodules/inplant-core-fe/i18n'
import tutelageControl from '@mv-submodules/vidim-tutelage-fe/i18n'
import components from '@mv-submodules/inplant-components-fe/i18n'
import config from '@mv-submodules/inplant-config/i18n'

i18nextInit({
  // @ts-ignore
  resources: extend(true, {
/*     cn: {
      translation: {
        ...core.cn,
        ...tutelageControl.cn,
        ...components.cn,
        ...config.cn,
      },
    }, */
    de: {
      translation: {
        ...core.de,
        ...tutelageControl.de,
        ...components.de,
        ...config.de,
      },
    },
    es: {
      translation: {
        ...core.es,
        ...tutelageControl.es,
        ...components.es,
        ...config.es,
      },
    },
    fr: {
      translation: {
        ...core.fr,
        ...tutelageControl.fr,
        ...components.fr,
        ...config.fr,
      },
    },
/*     pl: {
      translation: {
        ...core.pl,
        ...tutelageControl.pl,
        ...components.pl,
        ...config.pl,
      },
    }, */
/*     ru: {
      translation: {
        ...core.ru,
        ...tutelageControl.ru,
        ...components.ru,
        ...config.ru,
      },
    }, */
    en: {
      translation: {
        ...core.en,
        ...tutelageControl.en,
        ...components.en,
        ...config.en
      },
    },
    it: {
      translation: {
        ...core.it,
        ...tutelageControl.it,
        ...components.it,
        ...config.it
      },
    },
  }, {
    en: {
      translation: {
        ...overwrites.en,
      }
    },
    it: {
      translation: {
        ...overwrites.it,
      }
    },
    de: {
      translation: {
        ...overwrites.de,
      }
    },
    es: {
      translation: {
        ...overwrites.es,
      }
    },
    fr: {
      translation: {
        ...overwrites.fr,
      }
    },
  })
})

export default i18n
