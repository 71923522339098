import { RouteProps } from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/SideBar/SideBar'
import { maintenancesRoutes } from '@mv-submodules/vidim-tutelage-fe'

export const aclRoutes = (sroutes: RouteProps[], forbiddenActions: string[], loginSuccessPath: string) =>
  sroutes.filter(r => r)

export const aclRedirect = (loginSuccessPath: string): string => {
  const jwt = localStorage.getItem('jwt')
  if (jwt && process.env.NODE_ENV !== 'development') {
    const href = new URL('http://localhost:3000/')
    href.searchParams.set('token', jwt)
    return `/machines/auth?${href.searchParams}`
  }
  return loginSuccessPath
}

const routes: (userRoles: string[]) => RouteProps[] = userRoles => {

  return [maintenancesRoutes]
}

export default routes
