const en = {
  tutelage: {
    list: {
      target: 'Machines',
    },
    taskCreation: {
      target: {
        title: 'Machines',
        asset: {
          label: 'machines',
          radio: 'Specific machines',
          sel: 'selected machine',
          sels: 'selected machines',
          found: '{{value}} of {{totalValue}} found machines {{valueSpecific}}'
        },
        group: {
          radio: 'Machine groups',
          sel: 'Selected machine group',
          sels: 'Selected machine groups',
          found: '{{value}} of {{totalValue}} found machine groups {{valueSpecific}}'
        },
        checkbox: {
          label: 'Apply {{value}} to the machines',
        },
        radio: {
          specific: 'Create specific {{value}} for each machine involved',
          unique: 'Create single {{value}} for all machines involved',
        },
      },
    },
    detail: {
      target: 'Machines',
    },
  },
}

export default en