// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE ------------------------------------
import IconComponent from '../../../MVIcon/Icon'
import { BadgeCell } from '../..'

interface Props {
  badge: BadgeCell | undefined
}

const TableBadge = (props: Props) => {
  const { badge } = props

  if (!badge) {
    return null
  }

  return (
    <div className={`mv-badge mv-badge-${badge.semantic} mv-badge--table`}>
      {badge.icon && <IconComponent icon={badge.icon} /> || null}
    </div>
  )
}

export default TableBadge
