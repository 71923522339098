import componentsReducers from '@mv-submodules/inplant-components-fe/redux/reducers'
import { authReducer } from '@mv-submodules/inplant-core-fe/auth'
import { reducers as tutelageReducers } from '@mv-submodules/vidim-tutelage-fe'
import coreControlReducers from '@mv-submodules/inplant-core-fe/redux/reducers'

export const config = {
  generic: {
    loginSuccessPath: '/maintenances/my-task-list',
    useNewLogin: true,
    version: (window as any).VERSION || process.env.REACT_APP_VERSION || null,
    externalPasswordRecovery: {
      link: 'https://dcloud.dtindustry.com/machines/panel/authentication/recover-password-view',
    },
  },
}

export default {
  auth: authReducer,
  components: componentsReducers,
  tutelage: tutelageReducers,
  core: coreControlReducers,
  config: () => config,
}
