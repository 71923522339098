const fr = {
  tutelage: {
    list: {
      target: 'Machines',
    },
    taskCreation: {
      target: {
        title: 'Machines',
        asset: {
          label: 'machines',
          radio: 'Machines spécifiques',
          sel: 'machine sélectionnée',
          sels: 'machines sélectionnées',
          found: '{{value}} sur {{totalValue}} machines trouvées {{valueSpecific}}'
        },
        group: {
          radio: 'Groupes de machines',
          sel: 'Groupe de machines sélectionné',
          sels: 'Groupes de machines sélectionnés',
          found: '{{value}} sur {{totalValue}} groupes de machines trouvés {{valueSpecific}}'
        },
        checkbox: {
          label: 'Appliquer {{value}} aux machines',
        },
        radio: {
          specific: 'Créer {{value}} spécifique pour chaque machine impliquée',
          unique: 'Créer un seul {{value}} pour toutes les machines impliquées',
        },
      },
    },
    detail: {
      target: 'Machines',
    },
  },
}

export default fr
