// * -------------------------------- NPM --------------------------------------
import React, { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Breadcrumbs from './Breadcrumbs'
import SideBar, { SideBarProps } from './SideBar/SideBar'
import { BreadcrumbsProps } from './Breadcrumbs'

interface NavigationProps {
  navigationVisibility: boolean
  sidebar: SideBarProps
  children: React.ReactNode
  breadcrumbs: Omit<BreadcrumbsProps, 'onClickMobileMenu'>
}

const Navigation = (props: NavigationProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const [showSidebar, setShowSidebar] = useState<boolean>(false)
  const { sidebar, navigationVisibility, children, breadcrumbs } = props

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------

  return (
    <div className={'mv-body'}>
      {(navigationVisibility && (
        <SideBar
          {...sidebar}
          visible={showSidebar}
          onClickMobileMenu={() => {
            setShowSidebar(prev => !prev)
          }}
        />
      )) ||
      null}
      <div className={'mv-right-content'}>
        {(props.navigationVisibility && (
          <Breadcrumbs
            {...breadcrumbs}
            onClickMobileMenu={() => {
              setShowSidebar(prev => !prev)
            }}
          />
        )) ||
        null}
        {children}
      </div>
    </div>
  )
}

export default Navigation
