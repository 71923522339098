// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from '../MVIcon/Icon';

interface Props {
  visible: boolean
  customModalBody?: boolean
  title?: string
  children: React.ReactNode
  onClose: () => void
}

interface OwnState {
  additionalClassName: string
}

class ModalFullContent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      additionalClassName: ''
    }
    this.handleClose = this.handleClose.bind(this)
  }

  public componentDidMount() {
    setTimeout(() => {
      this.setState({additionalClassName: 'open'})
    }, 50)
    setTimeout(() => {
      document.getElementsByTagName("body")[0].style.overflow = 'hidden'
    }, 250)
  }

  public handleClose() {
    document.getElementsByTagName("body")[0].style.overflow = 'auto'
    setTimeout(() => {
      this.setState({additionalClassName: ''})
      setTimeout(() => {
        this.props.onClose()
      }, 50)
    }, 50)
  }

  public componentWillUnmount() {
    setTimeout(() => {
      document.getElementsByTagName("body")[0].style.overflow = 'auto'
    }, 250)
  }

  public render() {
    const {props} = this
    const {additionalClassName} = this.state
    // const isSidebarOpened = document.getElementsByClassName("")
    return (
      this.props.visible ? (
          <div className={`modal-full-width ${additionalClassName}`}>
            <div className="modal-full-width-content">
              <div className="modal-header">
                {props.title && <h5 className="modal-title">{props.title}</h5>}
                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>
                  <IconComponent icon={"times"} size="sm"/>
                </button>
              </div>
              {props.customModalBody ? props.children : (
                <div className="modal-body">{props.children}</div>
              )}
            </div>
          </div>
        )
        : null
    )
  }
}

export default ModalFullContent