// * -------------------------------- NPM --------------------------------------
import React, { useEffect } from 'react'
import { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from '../../MVIcon/Icon'
import InputComponent from './InputComponents'
import useGenericInputHook from '../../../../mvfunctions/hooks/useGenericInputHook'
import { CheckboxItem } from '../Checkbox'
import {
  IMultiSelectComponent,
  ISingleSelectComponent,
  OtherInputProps,
  SelectValueMultiSelectComponent,
} from '../types'
import { IconProps } from '../../../../mvtypes/base'
import { getUniqueId } from '../../../../mvfunctions/helpers/stringHelper'
import { handleClickLocation } from '../../../../mvfunctions/handleEvents'
import { useDidUpdate } from '../../../../mvfunctions/hooks/hooksHelper'

/**
 * !Don't use this component, Use only Input
 *
 * This component expose a @callback onChange that return the current value
 *
 * props @param defaultOption if disable can be a generic object for example {label: '--', value: '--'}, if not disable must be an element of items
 */
const SelectComponent = (props: (ISingleSelectComponent | IMultiSelectComponent) & IconProps & OtherInputProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  switch (props.type) {
    case 'singleSelect':
      return <SingleSelectComponent {...props} />
    case 'multiSelect':
      return <MultiSelectComponent {...props} />
  }
}

const SingleSelectComponent = <T extends string>(props: ISingleSelectComponent<T> & IconProps & OtherInputProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const inputHook = useGenericInputHook(
    props.controls?.map(c => ({
      control: () => c.control(value),
      error: c.error,
    }))
  )

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const [value, setValue] = useState<string>(props.options.defaultOption.value)

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    inputHook.retry()
  }, [value])

  useEffect(() => {
    if (props.onChangeState) {
      props.onChangeState(inputHook.state)
    }
  }, [inputHook.state])

  useDidUpdate(() => {
    if (
      props.overrideValue &&
      props.options.defaultOption.value !== null &&
      props.options.defaultOption.value !== undefined
    ) {
      props.onChange?.(props.options.defaultOption.value as T)
      setValue(props.options.defaultOption.value)
    }
  }, [props.options.defaultOption.value])

  useDidUpdate(() => {
    // array of item is changed and the previous selected is no more present. Will be selected the first available in options
    const elementSelected = [
      ...props.options.items,
      ...(props.options.defaultOption.disable ? [{ label: '', value: '' }] : []),
    ].find(el => el.value === value)?.value

    if (
      elementSelected === undefined &&
      props.options.items[0]?.value !== undefined &&
      props.options.items[0]?.value !== null
    ) {
      props.onChange?.(props.options.items[0].value)
      setValue(props.options.items[0].value)
    }
  }, [props.options.items.map(item => item.value)])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  if (props.readonly) {
    let label = props.options.items.find(el => el.value === value)?.label || ''

    if (props.options.defaultOption.disable && !label) {
      label = props.options.defaultOption.label
    }
    return (
      <InputComponent
        {...props}
        initialValue={label}
        kind={'input'}
        type={'text'}
        onChange={undefined}
        controls={undefined}
        onFocus={props.onFocus}
      />
    )
  }

  return (
    <div className={`mv-form__select ${props.validity || ''}`}>
      <select
        name={props.name}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        className={`mv-custom-select ${
          props.icon ? 'mv-input-group__form-control' : 'mv-form-control'
        } ${props.validity || ''}`}
        disabled={props.disabled || props.isDisable}
        id={props.id}
        value={value}
        onChange={event => {
          setValue(event.currentTarget.value)
          props.onChange?.(event.currentTarget.value as T, event)
        }}
      >
        {props.options.defaultOption.disable && (
          <option value={props.options.defaultOption.value} disabled={true}>
            {(props.options && props.options.defaultOption.label) || ''}
          </option>
        )}
        {props.options.items.map((option, index) => (
          <option key={`filter-option-${option.value}${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <span className={'mv-select__icon'}>
        <IconComponent icon={'chevron-down'} />
      </span>
    </div>
  )
}

const MultiSelectComponent = <T extends string>(props: IMultiSelectComponent<T> & IconProps & OtherInputProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const nameMSelect = `mv-multiselect`

  const [values, setValues] = useState<Array<SelectValueMultiSelectComponent<T>>>(
    props.options.items.map(i => ({ selected: i.selected || false, value: i.value, label: i.label }))
  )
  const [isOpenMultiSelect, setIsOpenMultiSelect] = useState<boolean>(false)
  const [uniqueId] = useState(getUniqueId(props.label))

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const handleClick = (event: MouseEvent) => {
    const specifiedElement: HTMLDivElement | null = document.getElementById(uniqueId) as HTMLDivElement | null
    handleClickLocation(specifiedElement, event, () => setIsOpenMultiSelect(false))
  }
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  if (props.readonly) {
    return (
      <InputComponent
        {...props}
        initialValue={values
          .filter(v => v.selected)
          .map(el => el.label)
          .join(', ')}
        kind={'input'}
        type={'text'}
        onChange={undefined}
        controls={undefined}
        onFocus={props.onFocus}
      />
    )
  }

  return (
    <div id={`${uniqueId}`} className={`mv-form__select ${props.validity}`}>
      <input
        style={{ cursor: 'pointer' }}
        className={`mv-custom-select mv-form-control ${nameMSelect} ${
          isOpenMultiSelect ? `${nameMSelect}--open` : ''
        } ${props.validity}`}
        onClick={() => {
          setIsOpenMultiSelect(prev => !prev)
        }}
        placeholder={props.placeholder}
        autoFocus={props.autoFocus}
        value={values
          .filter(v => v.selected)
          .map(el => el.label)
          .join(', ')}
        readOnly={true}
        onFocus={props.onFocus}
      />
      <span className={'mv-select__icon'}>
        <IconComponent icon={'chevron-down'} />
      </span>
      <div className={`${nameMSelect}__menu`}>
        {props.options.items.map((i, index) => (
          <div key={i.value} className={`${nameMSelect}__item`}>
            <CheckboxItem
              value={i.value}
              label={i.label}
              initialSelected={i.selected}
              onChange={checked => {
                setValues(prev => {
                  const newValues = [...prev]
                  newValues[index] = { ...i, selected: checked }
                  props.onChange?.(newValues)
                  return newValues
                })
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default SelectComponent
