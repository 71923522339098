// * -------------------------------- NPM --------------------------------------
import { Client } from 'raven'

declare global {
  interface Window {
    Raven: Client
  }
}

export enum NodeEnv {
  development = 'development',
  production = 'production',
  staging = 'staging',
  undefined = 'undefined',
}
export const getNodeEnv = (): NodeEnv => {
  if (
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === NodeEnv.development ||
    (process.env as any).NODE_ENV === NodeEnv.staging
  ) {
    return NodeEnv.development
  }
  if ((process.env as any).NODE_ENV === NodeEnv.staging) {
    return NodeEnv.staging
  }
  if (process.env.NODE_ENV === NodeEnv.production) {
    return NodeEnv.production
  }
  return NodeEnv.undefined
}

/**
 * @param location File where consoleError is fired
 * @param method Method that generated the problem
 * @param content The error as Error or string
 * @param mode where the error is to display
 */
export const logger = (
  location: string,
  method: string = '',
  content: unknown, // string | Error
  mode?: 'console' | 'server' | 'all'
) => {
  const message = `\nIn: ${location}\n${method !== '' ? `On Execution: ${method}\n` : ``}Error: ${content}`

  const manageContent = () => {
    if (typeof content === 'string') {
      window.Raven.captureMessage(message)
    } else if (content instanceof Error) {
      window.Raven.captureException(content)
    }
  }

  const isServerMode = (): boolean => {
    return (mode && ['server', 'all'].includes(mode)) || false
  }

  const isConsoleMode = (): boolean => {
    return (mode && ['console', 'all'].includes(mode)) || false
  }

  const consoleMode = isConsoleMode()
  const serverMode = isServerMode()
  const nodeEnv = getNodeEnv()

  if (nodeEnv === NodeEnv.production) {
    if (consoleMode) {
      console.error(message) //tslint:disable-line
    }
    if (!mode || serverMode) {
      manageContent()
    }
  }

  if (nodeEnv === NodeEnv.development) {
    if (!mode || consoleMode) {
      console.error(message) //tslint:disable-line
    }
    if (serverMode) {
      manageContent()
    }
  }
}

/**
 * Simple console.log wrapper that write message in console only in development mode
 * @param message
 * @param optionalParams
 */
export const consoleLog = (message: any, ...optionalParams: any[]) => {
  const env = getNodeEnv()
  if (env === NodeEnv.development) {
    console.log(message, ...optionalParams) //tslint:disable-line
  } else {
    // ! PRODUCTION MODE: NO console.log()
  }
}
