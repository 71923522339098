// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { Redirect, Route, withRouter, RouteComponentProps, RouteProps } from 'react-router'
import { connect } from 'react-redux'

interface StateProps {
  loggedIn: boolean
}

interface OwnProps extends RouteComponentProps<any> {
  failPath?: string
}

const mapStateToProps = (state: any): StateProps => ({
  loggedIn: state.auth.loggedIn,
})

type Props = RouteProps & StateProps & OwnProps

function PrivateRoute(props: Props) {
  if (props.loggedIn) {
    return <Route {...props} />
  }
  return (
    <Redirect
      to={{
        pathname: props.failPath || '/',
        state: { from: props.location },
      }}
    />
  )
}

export default withRouter(connect(mapStateToProps)(PrivateRoute))
