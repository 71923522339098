// * -------------------------------- NPM --------------------------------------
import React from 'react'

interface Props {
  length?: number
}

const PlaceholderLoader = ({ length = 5, ...props }: Props) => {
  return <div className={'mv-placeholder-loader'}>{Array.from({ length }, _i => '-')}</div>
}

export default PlaceholderLoader
