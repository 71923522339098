import { RouteProps } from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/SideBar/SideBar'
import UnknownPage from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/UnknownPage'
import { User } from '@mv-submodules/inplant-core-fe/types'

export const composeMaintenanceRoutes = (routeGroup: RouteProps, user: User): RouteProps | undefined => {
  const aclRoute = JSON.parse(JSON.stringify(routeGroup))
  aclRoute.visible = user.can(`view.${routeGroup.path}`)

  if (routeGroup.children) {
    aclRoute.children = []
    routeGroup.children.forEach(routeChild => {
      const newRouteChild = Object.assign({},routeChild)
      if (user.can(`view.${routeChild.path}`)) {
        newRouteChild.visible = newRouteChild.visible ? user.can(`nav.${routeChild.path}`) : false
        aclRoute.children?.push(newRouteChild)
      } else {
        newRouteChild.visible = false
        newRouteChild.component = UnknownPage
        aclRoute.children?.push(newRouteChild)
      }
    })
  }
  return aclRoute
}
