// * -------------------------------- NPM --------------------------------------
import _groupBy from 'lodash/groupBy'
import _isEqual from 'lodash/isEqual'

/**
 * @returns Given an object it retrieves the value of the slug. Slug can be with dot.
 * @example
 * ```
 * const t = {
 *    test: "ciao",
 *    prova: {
 *      c: "pippo",
 *    },
 * }
 * cont value = getDataFromObject("prova.c",t)
 * value => "pippo"
 * ```
 */
export const getDataFromObject = <T>(slug: string, data: T): any =>
  data &&
  slug
    .split('.')
    .reduce((acc: any, inputName: string) => (acc && acc[inputName] != null ? acc[inputName] : undefined), data)

/**
 * Add a new key value or update the value in the object `obj` without changing its shape
 *
 * @example
 * ```
 * const t = {
 *   test: "ciao",
 *   prova: {
 *      c: "pippo"
 *   },
 * }
 * const result = addValueInObject(t, "prova.2", 1)
 * result => { test: 'ciao', prova: { 2: 1, c: 'pippo' } }
 * }
 * ```
 */
export const addValueInObject = (obj: any, slug: string, value: any) => {
  const slugs = slug.split('.')

  return addValueInObjectRecursive(obj, slugs, value)
}

const addValueInObjectRecursive = (obj: any, slugs: string[], value: any): any => {
  for (let i = 0; i < slugs.length; i++) {
    if (i === slugs.length - 1) {
      if (obj) {
        if (typeof obj === 'object') {
          obj[slugs[i]] = value
        }
      } else {
        obj = {}
        obj[slugs[i]] = value
      }
      return obj
    } else {
      return (obj = { ...obj, [slugs[i]]: addValueInObjectRecursive(obj[slugs[i]], slugs.splice(1), value) })
    }
  }
}

/**
 * Reduce an object to a single level
 *
 * @example
 * ```
 * const obj = {
 *  prova: [
 *      {1:{v:3,a: 3},2:2}
 *        ],
 *   ciao: {
 *       pippo: 2
 *   }
 *  }
 * const result = reduceObject(obj)
 * result => { prova: [ { 2: 2, '1.v': 3, '1.a': 3 } ], 'ciao.pippo': 2 }
 * }
 * ```
 */
export const reduceObject = (obj: { [key: string]: any }) => {
  return reduceObjectRecursive(obj, '')
}

const reduceObjectRecursive = (obj: any, prevSlug: string): any => {
  let objCreated = {}
  if (obj && typeof obj === 'object') {
    Object.entries(obj).forEach(([key, value]) => {
      const newSlug = (prevSlug && prevSlug + '.' + key) || key

      if (Array.isArray(value)) {
        objCreated = { ...objCreated, [key]: [...value.map(v => reduceObjectRecursive(v, ''))] }
      } else if (typeof value === 'object') {
        objCreated = { ...objCreated, ...reduceObjectRecursive(value, newSlug) }
      } else {
        objCreated = { ...objCreated, [newSlug]: value }
      }
    })
  } else if (obj && Array.isArray(obj)) {
    return obj.map(o => reduceObjectRecursive(o, ''))
  } else {
    return obj
  }
  return objCreated
}

/**
 * Check if an object has a specific property and return the object with the specific inferred type
 *
 * Use with caution does not ensure that the object is of the inferred type.
 *
 * This function is to be used when you have two interfaces with known parameters and
 * you want to distinguish through a property present in one interface and not in the other.
 * @param object
 * @param uniquePropsToCheck
 *
 * @example
 *
 * if (instanceOf<User>(value, 'displayName')) {
 *    // now value is of type User!
 *    valueToRender = <Text text={value.displayName} />
 *  }
 */
export function instanceOf<T>(object: any, uniquePropsToCheck: string | string[],propsIn: 'And' | 'Or' = 'And'): object is T {
  if (Array.isArray(uniquePropsToCheck)) {
    if (propsIn === 'And') {
      return uniquePropsToCheck.reduce((acc, curr) => object && object[curr] && acc, true)
    }
    return uniquePropsToCheck.reduce((acc, curr) => object && object[curr] || acc, false) 
  }
  return object && uniquePropsToCheck in object
}

/**
 * Creates an object composed of keys generated from the results of running each element of collection through
 * iteratee. The corresponding value of each key is an array of the elements responsible for generating the
 * key. The iteratee is invoked with one argument: (value).
 *
 * @param collection The collection to iterate over.
 * @param iteratee The function invoked per iteration.
 * @return Returns the composed aggregate object.
 */
export const groupBy = _groupBy

/**
 * Performs a deep comparison between two values to determine if they are
 * equivalent.
 *
 * **Note:** This method supports comparing arrays, array buffers, booleans,
 * date objects, error objects, maps, numbers, `Object` objects, regexes,
 * sets, strings, symbols, and typed arrays. `Object` objects are compared
 * by their own, not inherited, enumerable properties. Functions and DOM
 * nodes are **not** supported.
 *
 * @category Lang
 * @param value The value to compare.
 * @param other The other value to compare.
 * @returns Returns `true` if the values are equivalent, else `false`.
 * @example
 *
 * var object = { 'user': 'fred' };
 * var other = { 'user': 'fred' };
 *
 * _.isEqual(object, other);
 * // => true
 *
 * object === other;
 * // => false
 */
export const isEqual = _isEqual
