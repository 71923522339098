// * -------------------------------- NPM --------------------------------------
import React, { useEffect } from 'react'
import { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Button from './Button'
import Linkable, { LinkableProps } from '../MVLink/Linkable'
import Loader from '../MVLoaders/Loader'
import { BaseButtonProps } from './types'
import { IFlexItem } from '../MVFlex/FlexItem'
import { IconProps, IsLoading } from '../../../mvtypes/base'
import { Kind } from '../../../mvtypes/kind'
import { getUniqueId } from '../../../mvfunctions/helpers/stringHelper'
import { handleClickLocation } from '../../../mvfunctions/handleEvents'

export type DropdownActions = Array<DropdownAction | LinkableDropdownAction>

export interface DropdownAction extends Kind<'action'> {
  label: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export interface LinkableDropdownAction extends LinkableProps, Kind<'link-action'>, IFlexItem {
  label: string
}

export interface DropdownButtonProps extends BaseButtonProps, IconProps, IsLoading {
  actions: DropdownActions
}

const ddown = 'mv-btn-ddown'

const DropdownButton = (props: DropdownButtonProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  type MenuStates = 'open' | 'close'
  const { icon, label, variant, semantic, size, isLoading, isDisable, actions } = props
  const [menuState, setMenuState] = useState<MenuStates>('close')
  const [uniqueId] = useState(getUniqueId(props.label))

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const handleClick = (event: MouseEvent) => {
    const specifiedElement: HTMLDivElement | null = document.getElementById(uniqueId) as HTMLDivElement | null
    handleClickLocation(specifiedElement, event, () => setMenuState('close'))
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const renderMenuState = (): string => {
    switch (menuState) {
      case 'open':
        return `${ddown}--open`
      case 'close':
        return ''
    }
  }

  const renderDropDownItem = (
    action: DropdownAction | LinkableDropdownAction,
    className: '--first' | '--last' | ''
  ) => {
    switch (action.kind) {
      case 'action':
        return (
          <DropdownItem
            name={className}
            {...(action as DropdownAction)}
            onClick={event => {
              if (action.onClick) {
                action.onClick(event)
              }
              setMenuState('close')
            }}
          />
        )
      case 'link-action':
        return <LinkDropdownItem {...action} name={className} kind={'action'} />
    }
  }

  return (
    <div
      id={uniqueId}
      className={`${ddown} ${(variant && `${ddown}--outline`) || ''} ${renderMenuState()} ${(isLoading &&
        `${ddown}--loading`) ||
        ''}`}
    >
      <Button
        isDisable={isDisable}
        label={label}
        icon={icon}
        semantic={semantic}
        variant={variant}
        size={size}
        dropDown={true}
        onClick={() => {
          switch (menuState) {
            case 'open':
              setMenuState('close')
              break
            case 'close':
              setMenuState('open')
              break
          }
        }}
      />
      <div className={`${ddown}__menu`}>
        {actions.map((action, index) => {
          return (
            <React.Fragment key={index}>
              <div className={`${ddown}__divider dropdown-divider`} />
              {renderDropDownItem(action, index === 0 ? '--first' : index === actions.length - 1 ? '--last' : '')}
            </React.Fragment>
          )
        })}
        {(isLoading && <Loader />) || null}
      </div>
    </div>
  )
}

const DropdownItem = (props: DropdownAction & { name: '--first' | '--last' | '' }) => {
  const { name } = props
  return (
    <button
      className={`${ddown}__item ${name ? `${ddown}__item${name}` : ''} dropdown-item`}
      {...props}
      onClick={e => {
        if (props.onClick) {
          props.onClick(e)
        }
      }}
    >
      <span className={'mv-btn__text'}>{props.label}</span>
    </button>
  )
}

const LinkDropdownItem = Linkable(DropdownItem)

export default DropdownButton
