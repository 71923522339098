// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Button from '../MVButtons/Button'
import Flex, { AlignItems } from '../MVFlex/Flex'
import Input from '../MVInput/Input'
import Modal from '../MVModal/Modal'
import Tree, { TreeMultiSelectProps } from '../MVTree/Tree'
import { IFlexItem } from '../MVFlex/FlexItem'
import { Icon } from '../../../services/icon'
import { LightNode } from '../MVTree/types'
import { WithTranslation } from '../../../mvtypes/base'
import { getNodeFromString } from '../MVTree/function'

export interface TreeFilterProps {
  hideInfoFeedback?: boolean
  label: string
  kind: 'tree',
  buttonLabel?: string
  buttonIcon?: Icon
  placeholder?: string
}

export type TreeComponents = TreeMultiSelectProps & TreeFilterProps & IFlexItem


const TreeFilter = (props: TreeComponents & WithTranslation) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { t } = props.translation
  const base = props.translation.base + '.treeFilter'
  const [showTreeFilter, setShowTreeFilter] = useState(false)
  const [inputValue, setInputValue] = useState<string>(getValue(props.initialSelected))

  const [valuesSelected, setValueSelected] = useState<LightNode[]>([])


  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  function getValue(values: Array<string | LightNode> | undefined): string {
    return values?.map(iS => {
      if (typeof iS === 'string') {
        return getNodeFromString(props.data, iS)?.label || ''
      }
      return iS?.label || ''
    }).join(', ') || ''

  }

  const { grow, shrink, basis } = props

  const handleClickModal = () => {
    setShowTreeFilter(prev => !prev)
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <>
      {showTreeFilter &&
      <Modal
        disableModalHeader={true}
        onClose={handleClickModal}
        visible={true}
        closeButtonLabel={t(`${base}.close`)}
        verticalPosition={'top-center'}
        rightFooterContents={[{
          label: t(`${base}.apply`),
          type: 'button',
          kind: 'button',
          semantic: 'primary',
          onClick: () => {
            props.onChange?.(valuesSelected)
            setInputValue(getValue(valuesSelected))
            handleClickModal()
          },
        }]}
      >
        <Tree {...props}
              selection={'MultiSelect'}
              onChange={nodes => {
                setValueSelected(nodes)
              }} />
      </Modal>}
      <Flex
        key={`filter_${props.id}`}
        alignItems={AlignItems.end}
        grow={grow}
        shrink={shrink}
        basis={basis}>
        <Input
          initialValue={inputValue}
          overrideValue={true}
          kind={'input'}
          label={props.label}
          readonly={true}
          placeholder={props.placeholder}
          hideInfoFeedback={props.hideInfoFeedback}
          grow={1}
        />
        <Button
          semantic={'secondary'}
          grow={0}
          label={props.buttonLabel}
          icon={props.buttonIcon}
          onClick={handleClickModal} />
      </Flex>
    </>)
}

export default TreeFilter