import { getContainer, TYPES } from '@mv-submodules/inplant-components-fe/services/binding'
import {
  IconComponentService,
  IconComponentServiceProps,
  IconCustomFamily,
  IconCustomName,
  IconFamily,
  IconName,
} from '@mv-submodules/inplant-components-fe/services/icon'
import { IconPrefix, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import VidimIconComponent, { addVidimIcon } from '../ui/components/widgets/VidimIcon/VidimIcon'
import vidimIcon from '../assets/vidimIcon'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas, far)
addVidimIcon('vidim-loader', vidimIcon)

const WrapFontAwesomeIcon = (props: IconComponentServiceProps) => {
  function familyToPrefix(f: 'regular' | 'solid' | undefined): IconPrefix {
    if (f === 'regular') {
      return 'far'
    }
    return 'fas'
  }

  const { icon, ...otherProps } = props

  let family: IconFamily | IconCustomFamily | undefined
  let name: IconName | IconCustomName | undefined

  if (Array.isArray(props.icon)) {
    family = props.icon[0]
    name = props.icon[1]
  } else {
    name = props.icon
  }

  if (family === 'custom') {
    return <VidimIconComponent iconName={name} />
  }

  const newProps: { icon: [IconPrefix, IconName] } = { icon: [familyToPrefix(family), name as IconName] }
  return <FontAwesomeIcon {...newProps} {...otherProps} />
}

const initIcons = (container: ReturnType<typeof getContainer>) => {
  container
    .bind<IconComponentService>(TYPES.IconComponentService)
    .toConstantValue(new IconComponentService(WrapFontAwesomeIcon))
}

export default initIcons
