// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from '../MVIcon/Icon'
import Linkable, { LinkableProps } from '../MVLink/Linkable'
import Loader from '../MVLoaders/Loader'
import { BaseButtonProps, renderButtonStyle } from './types'
import { Clickable } from '../../../mvtypes/onFunction'
import { IFlexItem, renderFlexItem } from '../MVFlex/FlexItem'
import { IconProps } from '../../../mvtypes/base'

export interface ButtonProps
  extends Clickable<[React.MouseEvent<HTMLButtonElement>]>,
    BaseButtonProps,
    IconProps,
    IFlexItem {}

const Button = (props: ButtonProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------

  const { icon, label, variant, onClick, semantic, size, isLoading, isDisable, dropDown, type, displayAsText } = props
  const btn = 'mv-btn'

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const renderData = () => {
    return (
      <>
        {(icon && (
          <span className={`${btn}__icon`}>
            <IconComponent icon={icon} />
          </span>
        )) ||
          null}
        {label && <span className={`${btn}__text`}>{label}</span>}
        {dropDown && (
          <span className={`${btn}-ddown__icon-select`}>
            <IconComponent icon={'chevron-down'} />
          </span>
        )}
        {(isLoading && <Loader />) || null}
      </>
    )
  }

  return (
    <button
      className={`${btn} btn btn-${renderButtonStyle(semantic, variant, size, displayAsText)} ${
        isLoading ? `${btn}--loading` : ''
      } ${renderFlexItem({ ...(props as IFlexItem) })}`}
      onClick={e => {
        e.currentTarget.blur()
        if (onClick) {
          onClick(e)
        }
      }}
      disabled={isLoading || isDisable}
      type={(type && type) || 'button'}
      onBlur={props.onBlur}
      tabIndex={props.tabIndex}
      onFocus={props.onFocus}
    >
      {renderData()}
    </button>
  )
}

// * ------------------------------------------
// * Helpers Component that pre-fill some props
// * ------------------------------------------
export interface LinkButtonProps extends LinkableProps, BaseButtonProps, IconProps, IFlexItem {}
export const LinkButton = Linkable(Button)

export default Button
