import { getContainer, TYPES } from '../../inplant-components-fe/services/binding'
import { TranslationService } from '../../inplant-components-fe/services/translation'
import { i18next } from '@mv-submodules/inplant-core-fe/i18n/i18n'
import { mvDate } from '../../inplant-components-fe/mvfunctions/helpers/dateHelper'

const initTranslation = (container: ReturnType<typeof getContainer>) => {
  mvDate.setCurrentLanguage(i18next.language)
  container.bind<TranslationService>(TYPES.TranslationService).toConstantValue(new TranslationService(i18next))
}

export default initTranslation
