// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { useEffect, useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Flex, { Direction, JustifyContent } from '../MVFlex/Flex'
import Title from '../MVText/Title'
import { LinkButton } from '../MVButtons/Button'
import { useComponentsTranslation } from '../../../services/translation'

type Errors = '403' | '404'
type Redirects = '301'
type Statuses = Errors | Redirects

interface Props {
  redirectURLs?: string[]
  status?: Statuses
}

/**
 * Manages all unmanaged routes. If the path is one of `redirectURLs` then is done a window.refresh to push the external page.
 * @param props
 */
const UnknownPage = (props: Props) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const translations = useComponentsTranslation()

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const base = 'components.layout.unknownPage'
  const { t } = translations
  const name = 'unknown-page'
  const [status, setStatus] = useState<Statuses>(props.status || '403')

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- State Management --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    if (
      props.redirectURLs &&
      props.redirectURLs.reduce((acc, curr) => acc || curr.includes(window.location.pathname), false)
    ) {
      setStatus('301')
      window.location.reload()
    }
  }, [])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  function renderRedirect(stat: Redirects) {
    return <>
    </>
  }

  if (status === '301') {
    return renderRedirect(status)
  }


  return (
    <div className={`${name} container`}>
      <Flex spaceSize={'lg'} className={`${name}__box`}>
        <Flex direction={Direction.column} className={`${name}__box__info`} justifyContent={JustifyContent.center}>
          <Title level={1} title={'Ops!'} />
          <Title level={2} title={t(`${base}.${status || '403'}`)} />
        </Flex>
        <LinkButton
          label={t(`${base}.button`)}
          icon={"chevron-left"}
          semantic={'primary'}
          to={location => {
            const path = location.pathname.replace(location.pathname.substr(location.pathname.lastIndexOf('/')), '')
            return { ...location, pathname: path }
          }}
        />
      </Flex>
    </div>
  )
}

export default UnknownPage
