// * -------------------------------- NPM --------------------------------------
import React, { useState } from 'react'
import { Redirect } from 'react-router'

// * -------------------------------- COMPONENTS --------------------------------------
import Button from '@mv-submodules/inplant-components-fe/ui/components/MVButtons/Button'
import { InputPassword, InputText } from '@mv-submodules/inplant-components-fe/ui/components/MVInput/Input'
import { logger } from '@mv-submodules/inplant-components-fe/mvfunctions/logs'
import { objectValidity, StateType } from '@mv-submodules/inplant-components-fe/mvfunctions/hooks/useGenericInputHook'
import { useComponentsTranslation } from '@mv-submodules/inplant-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
import { legacyLoginUser, loginUser } from '../../../../redux/actions/auth'
import { BrandingManagerStore } from '@mv-submodules/inplant-core-fe/redux/reducers/branding'
import { useAppDispatch, useAppSelector } from '@mv-submodules/inplant-core-fe/redux/reducers'
import PasswordRecovery from './PasswordRecovery'

interface StateProps {
  fetching: boolean
  error: Error | null
  loggedIn: boolean
  useNewLogin: boolean
  themeDefault: BrandingManagerStore
}

enum FormSlugs {
  username = 'username',
  password = 'password',
}

interface Props {
  successPath: string
  image?: string
}

const Login = (props: Props) => {
  const { t } = useComponentsTranslation()
  const dispatch = useAppDispatch()

  const auth: StateProps = useAppSelector((state: any) => state.auth)
  const useNewLogin: boolean = useAppSelector((state: any) => state.config.generic.useNewLogin || false)

  const base = 'auth'
  const login = 'mv-login'

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [usernameState, setUsernameState] = useState<StateType>({ kind: 'notValid', message: '' })
  const [passwordState, setPasswordState] = useState<StateType>({ kind: 'notValid', message: '' })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      if (useNewLogin) {
        loginUser(username, password)(dispatch)
      } else {
        legacyLoginUser(username, password)(dispatch)
      }
    } catch (error) {
      logger('Login.tsx', 'loginUser()', error)
    }
  }

  if (auth.loggedIn) {
    return <Redirect to={props.successPath} />
  }

  return (
    <div className={`${login}-container`}>
      <div className={`${login}`}>
        <form onSubmit={handleSubmit}>
          {props.image ? <img src={`/${props.image}`} className={`${login}__logo`} alt={''} /> : undefined}
          <InputText
            label={t(`${base}.${FormSlugs.username}.label`)}
            id='username'
            name='username'
            aria-describedby='usernameHelp'
            placeholder={t(`${base}.${FormSlugs.username}.placeholder`)}
            autocomplete={"username"}  
            onChange={value => setUsername(value)}
            onChangeState={state => setUsernameState(state)}
            required={true}
            controls={[{ control: value => !!value, error: t(`${base}.${FormSlugs.username}.empty`) }]}
          />
          <InputPassword
            label={t(`${base}.${FormSlugs.password}.label`)}
            id='password'
            name='password'
            placeholder={t(`${base}.${FormSlugs.password}.placeholder`)}
            onChange={value => setPassword(value)}
            required={true}
            autocomplete={"current-password"}  
            controls={[{ control: value => !!value, error: t(`${base}.${FormSlugs.password}.empty`) }]}
            onChangeState={state => setPasswordState(state)}
          />
          <Button
            isDisable={!objectValidity({ usernameState, passwordState })}
            semantic={'primary'}
            isLoading={auth.fetching}
            type='submit'
            label={t('auth.submitText')}
          />
        </form>
        <PasswordRecovery translation={{ t, base }} />
      </div>
    </div>
  )
}

export default Login
