import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper/index'

const API_BASE_URL = (window as any).API_BASE_URL || process.env.REACT_APP_API_BASE_URL
const APIbaseURL = `${API_BASE_URL}/v1`

const initFetchWrapper = (i18n: any) => {
  FetchWrapper.configInstance('file', '', true, i18n)
  FetchWrapper.configInstance('auth', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('tutelage', `${API_BASE_URL}/v2`, true, i18n)
}

export default initFetchWrapper
