import React from 'react'
import Button, { ButtonProps, LinkButton, LinkButtonProps } from './Button'
import ButtonGroup, { GroupButtonProps } from './ButtonGroup'
import DropdownButton, { DropdownButtonProps } from './DropdownButton'

export type ButtonFactoryProps<OmitInterface extends {} = {}> =
  | (Omit<ButtonProps, keyof OmitInterface> & { action?: 'action' })
  | (Omit<LinkButtonProps, keyof OmitInterface> & { action?: 'link' })
  | (Omit<DropdownButtonProps, keyof OmitInterface> & { action?: 'dropdown' })
  | (Omit<GroupButtonProps, keyof OmitInterface> & { action?: 'group' })

const ButtonFactory = ({ action = 'action', ...props }: ButtonFactoryProps<{}>) => {
  switch (action) {
    case 'link':
      return <LinkButton {...(props as LinkButtonProps)} />
    case 'dropdown':
      return <DropdownButton {...(props as DropdownButtonProps)} />
    case 'group':
      return <ButtonGroup {...(props as GroupButtonProps)} />
    case 'action':
    default:
      return <Button {...props} />
  }
}

export default ButtonFactory
