// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'

// * -------------------------------- COMPONENTS --------------------------------------
import { Alert, Button, Loader } from '@mv-submodules/inplant-components-fe'

const monitorInterval = 60000 * 10 // monitoring interval, 10 minutes

interface VersionCheckerStateProps {
  updateNotificationEnabled: boolean
  version: string
}

const mapStateToProps = (state: any): VersionCheckerStateProps => ({
  updateNotificationEnabled: state.config.generic && state.config.generic.updateNotification && (
    state.config.generic.updateNotification === '1' ||
    state.config.generic.updateNotification === 1
  ),
  version: state.config.generic && state.config.generic.version || '',
})

type Props = WithTranslation & VersionCheckerStateProps

interface OwnState {
  currentVersion: null | string
  needsUpdate: boolean
  resetRunning: boolean
}

/**
 * Component that check if the front-end version is the same one from CI.
 * If not a notification is shown, asking you to update.
 * @class
 * @classdesc CI versione comes from env variable `REACT_APP_VERSION` (from `/config/config.js`)
 */
class VersionChecker extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      currentVersion: null,
      needsUpdate: false,
      resetRunning: false,
    }

    this._checkStore = this._checkStore.bind(this)
    this._monitorVersion = this._monitorVersion.bind(this)
    this._resetStore = this._resetStore.bind(this)
  }

  /**
   * Enable version check only if .env variable is set
   */
  public componentDidMount() {
    if (this.props.updateNotificationEnabled) {
      this._checkStore()
    }
  }

  public render() {
    const { t, updateNotificationEnabled } = this.props
    const { currentVersion, needsUpdate, resetRunning } = this.state

    return updateNotificationEnabled ? (
      <div className='version-checker'>
        {
          needsUpdate ? <Alert type={'warning'} title={t('versionChecker.newVersionTitle')}>{resetRunning ? <div>
            {t('versionChecker.resetting')}
            <Loader />
          </div> : <>
            <p>{t('versionChecker.newVersion')}</p>
            <Button
              variant={'primary'}
              size={'sm'}
              onClick={this._resetStore}
              label={t('versionChecker.pleaseUpdate')}
            />
          </>}</Alert> : null}

        {currentVersion ?
          <div title={t('versionChecker.currentVersion')} className='current-version'>{currentVersion}</div> : null}
      </div>
    ) : null
  }

  /**
   * Compares localStorage frontend version against CI version
   * If they are not the same a notification is shown.
   * It also starts timed version monitoring
   *
   * @private
   */
  private _checkStore() {
    const
      currentVersion = localStorage.getItem('FE_VERSION')

    if (currentVersion) {
      const needsUpdate = this.props.version !== localStorage.getItem('FE_VERSION')
      this.setState({
        needsUpdate,
        currentVersion,
      })
    } else {
      // current version not set, set it
      if (this

          .props
          .version
        &&
        this
          .props
          .version
        !==
        ''
      ) {
        localStorage
          .setItem(
            'FE_VERSION'
            ,
            this
              .props
              .version,
          )
      }

      this.setState({
        currentVersion: localStorage.getItem('FE_VERSION') || null,
      })
    }

    this._monitorVersion()
  }

  /**
   * Reset local storage after confirmation, then reloads the page (forces login)
   * @private
   */
  private _resetStore() {
    if (confirm(this.props.t('versionChecker.confirmUpdate'))) {
      this.setState({ resetRunning: true })

      localStorage.clear()

      setTimeout(() => {
        location.reload()
      }, 2000)
    }
  }

  /**
   * Checks if a new version is released.
   * @private
   */
  private _monitorVersion() {
    setInterval(() => {
      fetch('/config/config.js')
        .then(r => r.text())
        .then(r => {
          const matchVersion = r.match(/window.VERSION\s?=\s?'(.*)'/)
          const localVersion = localStorage.getItem('FE_VERSION')
          if (matchVersion) {
            if (matchVersion[1] !== localVersion) {
              this.setState({
                needsUpdate: true,
              })
            }
          }
        })
        .catch(e => console.log(e)) // tslint:disable-line
    }, monitorInterval)
  }
}

export default connect(mapStateToProps)(withTranslation()(VersionChecker))
