// * -------------------------------- NPM --------------------------------------
import React, { CSSProperties } from 'react'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from '../MVIcon/Icon'
import { Clickable } from '../../../mvtypes/onFunction'

export type FlexItemConstraint = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export type BasisConstraint = 10 | 20 | 21 | 22 | 23 | 24 | 25 | 30 | 33 | 40 | 50 | 60 | 70 | 75 | 90

export enum AlignSelf {
  start = 'mv-flex-item--align-self-start',
  end = 'mv-flex-item--align-self-end',
  center = 'mv-flex-item--align-self-center', // center
  stretch = 'mv-flex-item--align-self-stretch',
  baseline = 'mv-flex-item--align-self-baseline',
}

export enum TooltipPosition {
  rightCenter = 'mv-tooltip__info mv-tooltip__info-center mv-tooltip__info-right',
  rightTop = 'mv-tooltip__info mv-tooltip__info-top mv-tooltip__info-right',
  rightBottom = 'mv-tooltip__info mv-tooltip__info-bottom mv-tooltip__info-right',
  leftCenter = 'mv-tooltip__info mv-tooltip__info-center mv-tooltip__info-left',
  leftTop = 'mv-tooltip__info mv-tooltip__info-top mv-tooltip__info-left',
  leftBottom = 'mv-tooltip__info mv-tooltip__info-bottom mv-tooltip__info-left',
}

export interface IFlexItem {
  grow?: FlexItemConstraint
  shrink?: FlexItemConstraint
  alignSelf?: AlignSelf
  basis?: BasisConstraint
}

export const renderFlexItem = (props: IFlexItem): string => {
  const item = 'mv-flex-item'
  const { grow, shrink, alignSelf, basis } = props
  if (grow || shrink || alignSelf || basis) {
    const shrinkGrowString =
      shrink !== undefined || grow !== undefined
        ? `${item}-${shrink !== undefined ? `-${shrink}` : ''}${grow !== undefined ? `-${grow}` : ''}`
        : ''
    const alignCenterString = alignSelf !== undefined ? alignSelf : ''
    const basisString = basis !== undefined ? `${item}--basis-${basis}` : ''
    return ` ${item} ${shrinkGrowString} ${alignCenterString} ${basisString}`
  }
  return ``
}

interface TooltipProps {
  position?: 'top' | 'bottom' | 'left' | 'right'
  infoIconPosition?: TooltipPosition
  content: string | HTMLElement
  disableIcon?: boolean
}

interface Props extends IFlexItem, Clickable<[React.MouseEvent<HTMLDivElement>]> {
  className?: string
  style?: CSSProperties
  children?: React.ReactNode
  tooltip?: TooltipProps
  id?: string
}

const FlexItem: React.FC<Props & IFlexItem> = React.forwardRef(
  ({ className = '', ...props }, ref: React.Ref<HTMLDivElement>) => {
    const { style, tooltip } = props
    const newStyle: CSSProperties = (props.onClick && { cursor: 'pointer' }) || {}

    const renderElement = () => {
      return (
        <div
          id={props.id}
          ref={ref}
          className={`${className} ${renderFlexItem({ ...(props as IFlexItem) })}`}
          style={{ ...style, ...newStyle }}
          onClick={e => {
            props.onClick?.(e)
          }}
        >
          {props.children}
        </div>
      )
    }

    return (
      (tooltip && tooltip.content && (
        <div
          id={props.id}
          ref={ref}
          mv-tooltip-content={tooltip.content}
          mv-tooltip-position={tooltip.position || 'top'}
          className={`mv-tooltip ${className} ${renderFlexItem({ ...(props as IFlexItem) })}`}
          style={{ ...style, ...newStyle }}
          onClick={props.onClick}
        >
          {(!tooltip.disableIcon && (
              <IconComponent icon={'info-circle'} className={`${tooltip.infoIconPosition || TooltipPosition.rightTop}`} />
            )) ||
            null}
          {props.children}
        </div>
      )) ||
      renderElement()
    )
  },
)

export default React.memo(FlexItem)
