interface Action {
  type:string
}

const initialState = {}

export function pageConfig(state: {} = initialState, action: Action): {} {
  switch (action.type) {
    default:
      return state
  }
}
