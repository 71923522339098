// * -------------------------------- NPM --------------------------------------
import _uniqBy from 'lodash/uniqBy'
import _map from 'lodash/map'
import _differenceWith from 'lodash/differenceWith'
import { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import { isEqual } from './objectHelper'

/**
 * @param array
 * @param newOrder
 * @returns a copy of the array with the new order defined by the param `newOrder`
 *
 * @example
 * ```
 *  const example = ["ciao","come","va"]
 *  const exampleOrdered = changeOrderOf(example,[1,2,0])
 *  exampleOrdered => ["come","va","ciao"]
 * ```
 */
export const changeOrderOf = <T>(array: T[], newOrder: number[]): T[] => {
  if (array.length !== newOrder.length) {
    return array
  }

  const clonedArray = array
    .map((element, index) => {
      return { idx: newOrder[index], obj: element }
    })
    .sort((a, b) => {
      return a.idx - b.idx
    })
    .map(el => el.obj)
  return clonedArray
}

/**
 * This method is like `_.uniq` except that it accepts `iteratee` which is
 * invoked for each element in `array` to generate the criterion by which
 * uniqueness is computed. The iteratee is invoked with one argument: (value).
 *
 * @category Array
 * @param array The array to inspect.
 * @param [iteratee=_.identity] The iteratee invoked per element.
 * @returns Returns the new duplicate free array.
 * @example
 *
 * _.uniqBy([2.1, 1.2, 2.3], Math.floor);
 * // => [2.1, 1.2]
 *
 * // using the `_.property` iteratee shorthand
 * _.uniqBy([{ 'x': 1 }, { 'x': 2 }, { 'x': 1 }], 'x');
 * // => [{ 'x': 1 }, { 'x': 2 }]
 */
export const uniqElements = _uniqBy

/**
 * @param collection The collection to iterate over.
 * @param iteratee The function invoked per iteration.
 * @return Returns the new mapped array.
 */
export const collectionMap = _map

/**
 * Creates an array of unique `array` values not included in the other
 * provided arrays using the equality
 *
 * @param subject The arrays to inspect.
 * @param comparator the array used to make the difference
 * @returns Returns the new array of filtered values.
 * @example
 *
 * var objects = [{ 'x': 1, 'y': 2 }, { 'x': 2, 'y': 1 }];
 * _.differenceWith(objects, [{ 'x': 1, 'y': 2 }], _.isEqual);
 * // => [{ 'x': 2, 'y': 1 }]
 */
export const differenceOf = <T>(subject: T[], comparator: T[]) => _differenceWith(subject, comparator, isEqual)

export const useArray = <T>(defaultValues: T[]) => {
  const [array, setArray] = useState<T[]>(defaultValues)

  function push(element: T) {
    setArray(a => [...a, element])
  }

  function filter(callback: (value: T, index: number, array: T[]) => boolean) {
    setArray(a => a.filter(callback))
  }

  function update(index: number, newElement: T) {
    setArray(a => [...a.slice(0, index), newElement, ...a.slice(index + 1, a.length - 1)])
  }

  function removeAt(index: number) {
    setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length - 1)])
  }

  function clear() {
    setArray([])
  }

  return { array, set: setArray, push, filter, update, removeAt, clear }
}

export const useCircularArray = <T>(defaultValues: T[], size: number) => {
  const [index, setIndex] = useState(Math.min(defaultValues.length,size))
  const {array,update,clear,set,filter} = useArray(defaultValues)

  function increment() {
    setIndex(prev => (prev + 1) % size)
  }

  function push(element: T) {
    update(index, element)
    increment()
  }

  function setCircularArray(values:T[]) {
    set(values)
    setIndex(Math.min(values.length,size))
  }

  return {array,push,clear,set:setCircularArray,filter}
}
