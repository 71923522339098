import { useAppSelector } from '@mv-submodules/inplant-core-fe/redux/reducers'
import { useEffect, useState } from 'react'
import { ThemeImpl, themingFetchTime } from '@mv-submodules/inplant-core-fe/types/theme'
import { logger } from '@mv-submodules/inplant-components-fe/mvfunctions/logs'
import Assets from '@mv-submodules/inplant-core-fe/types/assets'
import { changeAssets, changeTheme } from '@mv-submodules/inplant-core-fe/redux/actions/branding'
import { useDispatch } from 'react-redux'

type BrandingResult = { theme: ThemeImpl, assets: Assets, kind: 'success' } | { kind: 'fetching' }

const useBranding = (): BrandingResult => {

  const dispatch = useDispatch()

  const storageTheme = useAppSelector(state => state?.core?.brandingManager?.theme)
  const storageAssets = useAppSelector(state => state?.core?.brandingManager?.assets)

  const [theme, setTheme] = useState(new ThemeImpl(storageTheme))
  const [assets, setAssets] = useState(new Assets(storageAssets))

  const [isLoadingTheme, setIsLoadingTheme] = useState(false)

  function setBranding(): void {
    let slugTheme = ''
    let slugAssets = ''

    // theme is not cached and is required from URL
    if (themingFetchTime.onBaseURL && storageTheme === undefined) {
      setIsLoadingTheme(true)
      try {
        const urlMappingFile = require('@mv-submodules/inplant-config/config/urlMapping')
        if (urlMappingFile) {
          slugTheme = slugAssets = urlMappingFile.getSlugFromURL()
        }
      } catch {
        logger(
          'Layout.tsx',
          'setBranding',
          'if ENV var `themingFetchTime.onBaseURL` is set then in config is required the file urlMapping [inplant-config/config/urlMapping does not exists]',
        )
      }
    }
    // retrieve theme and assets from localStorage
    else if (storageTheme && storageAssets) {
      slugTheme = storageTheme.themeName
      slugAssets = storageAssets.slug
    }

    Promise.all([changeTheme(slugTheme)(dispatch), dispatch(changeAssets(slugAssets))]).then(_result => {
      setIsLoadingTheme(false)
    })
  }

  useEffect(() => {
    setBranding()
  }, [])

  useEffect(() => {
    setTheme(new ThemeImpl(storageTheme))
  }, [storageTheme?.themeName])

  useEffect(() => {
    setAssets(new Assets(storageAssets))
  }, [storageAssets.slug])

  if (isLoadingTheme) {
    return { kind: 'fetching' }
  }
  return { kind: 'success', theme, assets }
}

export default useBranding