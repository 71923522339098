// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Text from '../MVText/Text'
import { BaseSemantics, CatSemantics } from '../../../mvtypes/base'
import { Clickable } from '../../../mvtypes/onFunction'
import { Kind } from '../../../mvtypes/kind'

export type BadgeSemantics = BaseSemantics | 'success' | 'info' | 'warning' | 'brand' | 'default' | CatSemantics

interface BaseBadge<T> extends Kind<T> {
  semantic: BadgeSemantics
}

interface WithText {
  title: string
  description?: string
}

interface WithCounter {
  counter: number | null | undefined
}

interface WithSize {
  size?: 'sm' | 'md' | 'lg'
}

interface CalendarBadgeProps
  extends BaseBadge<'calendar'>,
    WithText,
    WithCounter,
    Clickable<[React.MouseEvent<HTMLDivElement, MouseEvent>]> {}

interface BadgeProps extends BaseBadge<'badge'>, WithText, WithSize {}

interface NotificationBadgeProps extends BaseBadge<'notification'>, WithCounter {}

type Badges = CalendarBadgeProps | BadgeProps | NotificationBadgeProps

export const Badge = (props: Badges) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const mvb = 'mv-badge'

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const renderBadgeName = (): string => {
    switch (props.kind) {
      case 'badge':
        return `${mvb}--${props.size !== undefined ? props.size : 'md'}`
      case 'calendar':
        return `${mvb}--calendar`
      case 'notification':
        if (props.counter && props.counter <= 99) {
          return `${mvb}--notification`
        }
        if (props.counter && props.counter > 99) {
          return `${mvb}--circle`
        }
        return `${mvb}--hidden`
      default:
        return ''
    }
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const renderBadgeContent = (): JSX.Element => {
    switch (props.kind) {
      case 'badge':
        return renderSimpleBadge(props)
      case 'calendar':
        return renderCalendarBadge(props)
      case 'notification':
        return renderNotificationBadge(props)
      default:
        return <div />
    }
  }

  const renderSimpleBadge = (badge: BadgeProps) => {
    return renderText(badge.title, badge.description)
  }

  const renderCalendarBadge = (badge: CalendarBadgeProps) => {
    return (
      <React.Fragment>
        {renderText(badge.title, badge.description)}
        {renderCounter(badge.counter)}
      </React.Fragment>
    )
  }

  const renderNotificationBadge = (badge: NotificationBadgeProps) => {
    if (badge.counter !== null && badge.counter !== undefined && badge.counter <= 99) {
      return renderCounter(badge.counter)
    }
    return <React.Fragment />
  }

  const renderText = (title: string, description?: string) => {
    return (
      <div className={`${mvb}__text`}>
        <div className={`${mvb}__title`}>
          <Text text={title} />
        </div>
        {description !== undefined ? (
          <div className={`${mvb}__desc`}>
            <Text text={description} />
          </div>
        ) : null}
      </div>
    )
  }

  const renderCounter = (counter: number | null | undefined) => {
    if (counter !== null && counter !== undefined && counter > 0) {
      return (
        <div className={`${mvb}__counter`}>
          <Text text={counter.toString()} />
        </div>
      )
    } else {
      return <React.Fragment />
    }
  }

  return (
    <div
      className={`${mvb} ${mvb}-${props.semantic} ${renderBadgeName()}`}
      onClick={props.kind === 'calendar' ? props.onClick : undefined}
    >
      {renderBadgeContent()}
    </div>
  )
}

export default Badge
