// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from '../MVIcon/Icon'
import { generateSpace, SpaceType } from '../../../mvfunctions/bootstrapUtility'
import { Icon } from '../../../services/icon'

let icon: Icon = 'circle-notch'

export const setupLoaderIcon = (newIcon: Icon) => {
  icon = newIcon
}

const CenterLoader = () => (
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  <div className={`${generateSpace(SpaceType.margin, { top: 5 })} text-center`}>
    <IconComponent icon={icon} spin={true} size='3x' className='text-muted' />
  </div>
)

export default CenterLoader
