// * -------------------------------- NPM --------------------------------------
import { combineReducers } from 'redux'

// * -------------------------------- MODULE --------------------------------------
import { tableReducer,TableStore } from '../../ui/components/MVTable'

const componentsReducersMap = {
  tableReducer,
}

const componentsReducers = combineReducers(componentsReducersMap)

export default componentsReducers

export interface ComponentsReducers {
  tableReducer: TableStore
}
