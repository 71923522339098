export const handleClickLocation = (
  respectTo: HTMLElement | null | undefined,
  event: MouseEvent,
  onClickOutside?: () => void,
  onClickInside?: () => void
) => {
  const isClickInside = respectTo && event.target && respectTo.contains(event.target as Node)

  if (isClickInside && onClickInside) {
    onClickInside()
  }

  if (!isClickInside && onClickOutside) {
    onClickOutside()
  }
}
