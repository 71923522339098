import { Theme, themeList } from '@mv-submodules/inplant-core-fe/types/theme'
import { ActionCreator } from 'redux'
import Assets, { IAssets } from '@mv-submodules/inplant-core-fe/types/assets'
import { AppAction, AppManagerPersist } from '../reducers'
import { HealthChecker } from '../../types/healthChecker'

export const THEME_MANAGER_SUCCESS = 'THEME_MANAGER_SUCCESS'
export const THEME_MANAGER_IS_LOADING = 'THEME_MANAGER_IS_LOADING'
export const CHANGE_ASSETS_SUCCESS = 'CHANGE_ASSETS_SUCCESS'

export enum HeadProps {
  themeHead = 'stylesheet',
  logoHead = 'shortcut icon',
}

interface ThemeConfigManagerSuccess {
  type: typeof THEME_MANAGER_SUCCESS
  payload: Theme
}

interface ThemeConfigManagerIsFetching {
  type: typeof THEME_MANAGER_IS_LOADING
}

interface ChangeAssetsSuccess {
  type: typeof CHANGE_ASSETS_SUCCESS
  payload: IAssets
}

export const isLoadingThemeManager: ActionCreator<ThemeConfigManagerIsFetching> = (): ThemeConfigManagerIsFetching => {
  return {
    type: THEME_MANAGER_IS_LOADING,
  }
}

export const fetchingThemeManagerSuccess: ActionCreator<ThemeConfigManagerSuccess> = (
  theme: Theme
): ThemeConfigManagerSuccess => {
  return {
    type: THEME_MANAGER_SUCCESS,
    payload: theme,
  }
}

const changeAssetsSuccess: ActionCreator<ChangeAssetsSuccess> = (assets: IAssets): ChangeAssetsSuccess => {
  return {
    type: CHANGE_ASSETS_SUCCESS,
    payload: assets,
  }
}

export const changeTheme = (clientSlug?: string): ((dispatch: any) => Promise<void>) => {
  const fallbackChangeTheme = async (dispatch: Function) => {
    const defaultTheme = themeList.find(th => th.themeName === 'default')
    if (defaultTheme) {
      await setBrandingHead(defaultTheme.themeFile, HeadProps.themeHead).then(() => {
        dispatch(fetchingThemeManagerSuccess(defaultTheme.interface))
      })
    }
    return
  }

  return async dispatch => {
    dispatch(isLoadingThemeManager())
    if (clientSlug) {
      const theme = themeList.find(th => th.themeName === clientSlug)
      if (theme) {
        await setBrandingHead(theme.themeFile, HeadProps.themeHead)
          .then(() => {
            dispatch(fetchingThemeManagerSuccess(theme.interface))
          })
          .catch(() => {
            fallbackChangeTheme(dispatch)
          })
      } else {
        // fallback to default theme
        await fallbackChangeTheme(dispatch)
      }
    }
    return
  }
}

export const changeAssets = (clientSlug: string): AppAction<void> => async (dispatch, _getState) => {
  // params true --> fallback to default assets
  const newAssets: Assets = await Assets.init(clientSlug)
  await setBrandingHead(newAssets.favicon, HeadProps.logoHead).then(() => {
    dispatch(changeAssetsSuccess(newAssets.interface))
  })
  return
}

export const setBrandingHead = async (fileName: string, propertyToChange: HeadProps): Promise<void> => {
  if (fileName) {
    const linkList = document.createElement('link')
    const healthChecker = new HealthChecker()
    linkList.href = `/${fileName}?ver=${healthChecker.frontEndVersion}`
    linkList.id = propertyToChange
    linkList.rel = propertyToChange
    if (document.getElementById(propertyToChange)) {
      document.getElementById(propertyToChange)?.remove()
    }
    document.getElementsByTagName('head')[0].appendChild(linkList)
  }
}

export type ThemeManagerActions =
  | ThemeConfigManagerSuccess
  | ThemeConfigManagerIsFetching
  | AppManagerPersist
  | ChangeAssetsSuccess
