// * -------------------------------- NPM --------------------------------------
import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

// * -------------------------------- MODULE --------------------------------------
import CenterLoader from '../MVLoaders/CenterLoader'
import UnknownPage from './UnknownPage'

interface MainProps {
  pages?: React.ReactNode
  redirectURLs?: string[]
  children: React.ReactNode
  suspenseFallback?: React.ReactNode
  otherPages?: Array<{ path: string; component: React.ComponentType<any> }>
}

const Main = ({ suspenseFallback = <CenterLoader />, ...props }: MainProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <div id={`mv-content`}>
      <Suspense fallback={suspenseFallback}>
        <Switch>
          {props.pages}
          <Route key="/" exact={true} path="/" render={() => props.children} />
          {props.otherPages?.map((c, index) => (
            <Route key={index} exact={true} path={c.path} component={c.component} />
          ))}
          <Route render={() => <UnknownPage redirectURLs={props.redirectURLs} status={'404'} />} />
        </Switch>
      </Suspense>
    </div>
  )
}

export default Main
