// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import FilterInput from './FilterInput'
import { IFlexItem } from '../MVFlex/FlexItem'
import { IInputComponent } from '../MVInput/types'
import { useState } from 'react'
import debounce from 'lodash/debounce'

export interface ISearchFilter extends IInputComponent {
  throttle?: number
  savedSearchString?: string
  currentAppliedFilter?: string
  lastAppliedFilter?: string
}

type Props = ISearchFilter & IFlexItem

const SearchFilter = ({ clearable = true, ...props }: Props) => {
  const { currentAppliedFilter, lastAppliedFilter, savedSearchString, onChange, throttle } = props

  const [value, setValue] = useState(
    currentAppliedFilter === lastAppliedFilter && savedSearchString ? savedSearchString : null
  )

  const propagateOnChange = debounce(newValue => onChange?.(newValue), throttle || 200)

  const handleChange = (newValue: string) => {
    setValue(newValue)
    propagateOnChange(newValue)
  }

  return <FilterInput {...props} initialValue={value || ''} onChange={handleChange} kind={'input'} />
}

export default SearchFilter
