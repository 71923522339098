import { RouteProps } from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/SideBar/SideBar'
import { composeMaintenanceRoutes } from '@mv-submodules/inplant-core-fe/functions/aclGenerator/maintenances'
import { User } from '@mv-submodules/inplant-core-fe/types'

export const composeRoutes = (moduleRoutes: RouteProps[], user: User): RouteProps[] => {
  const newRoutes: RouteProps[] = []

  // forEach to give the possibility to remove an entire routeGroup
  moduleRoutes.forEach(routeGroup => {

    // pattern matching with module name to handle each module differently (if necessary, else use maintenances standard!)
    if (routeGroup.path.includes('maintenances')) {
      const result = composeMaintenanceRoutes(routeGroup, user)
      if (result) {
        newRoutes.push(result)
      }
    } else {
      newRoutes.push(routeGroup)
    }
  })
  return newRoutes
}