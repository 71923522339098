// * -------------------------------- NPM --------------------------------------
import DatePicker from 'react-datepicker'
import React, { useEffect } from 'react'
import { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import useGenericInputHook from '../../../../mvfunctions/hooks/useGenericInputHook'
import { DAY_MONTH_YEAR, mvDate } from '../../../../mvfunctions/helpers/dateHelper'
import { IDateComponent, IIntervalDateComponent, ISingleDateComponent, OtherInputProps } from '../types'
import { getUniqueId } from '../../../../mvfunctions/helpers/stringHelper'

const DateComponent = (props: IDateComponent & OtherInputProps) => {
  if (props.type === 'dateInterval') {
    return <IntervalDateComponent {...props} />
  }
  return <SingleDateComponent {...props} />
}

// * --------------------------------------------------------------------- SINGLE ----------------------------------------------------------------------------------------
const SingleDateComponent = React.memo(
  ({ dateFormat = DAY_MONTH_YEAR, ...props }: ISingleDateComponent & OtherInputProps) => {
    // * ----------------------------------------------------------------------------------------
    // * -------------------------------- HOOKs --------------------------------------
    // * ----------------------------------------------------------------------------------------
    const inputHook = useGenericInputHook(
      props.controls?.map(c => ({
        control: () => {
          return c.control(value)
        },
        error: c.error,
      }))
    )

    // * ----------------------------------------------------------------------------------------
    // * -------------------------------- INIT --------------------------------------
    // * ----------------------------------------------------------------------------------------
    const id = getUniqueId(props.id)
    let initialValue: Date | null = (props.initialValue && mvDate.getDateFromString(props.initialValue)) || null
    if (initialValue && !mvDate.isValid(initialValue)) {
      initialValue = null
    }
    const [value, setValue] = useState<Date | null>(initialValue)

    // * ----------------------------------------------------------------------------------------
    // * -------------------------------- STATE MANAGEMENT --------------------------------------
    // * ----------------------------------------------------------------------------------------
    useEffect(() => {
      inputHook.retry()
    }, [value])

    useEffect(() => {
      if (props.onChangeState) {
        props.onChangeState(inputHook.state)
      }
    }, [inputHook.state])

    /*
     * -----------------------------------------------------------------------
     * ---------------------------- RENDERs ---------------------------
     * -----------------------------------------------------------------------
     * */
    return (
      <DatePicker
        name={props.name}
        onChange={date => {
          if (date && date instanceof Date) {
            setValue(date)
            props.onChange?.(date)
          } else {
            setValue(null)
            props.onChange?.(null)
          }
        }}
        portalId={id}
        onBlur={props.onBlur}
        readOnly={props.readonly}
        isClearable={props.clearable}
        required={props.required}
        selected={value}
        clearButtonClassName={'mv-date-clear-button'}
        dateFormat={dateFormat}
        className={`mv-form-control ${props.validity}`}
        wrapperClassName={`mv-datepicker-wrapper mv-form-control ${props.validity}`}
        popperClassName={`mv-datepicker-popper`}
        autoComplete="off"
        minDate={(props.min && new Date(props.min)) || undefined}
        maxDate={(props.max && new Date(props.max)) || undefined}
        disabled={props.disabled || props.isDisable}
      />
    )
  },
  (prevProps, nextProps) => {
    if (
      nextProps.initialValue === prevProps.initialValue &&
      nextProps.validity === prevProps.validity &&
      nextProps.readonly === prevProps.readonly &&
      nextProps.disabled === prevProps.disabled &&
      nextProps.isDisable === prevProps.isDisable
    ) {
      return true // no re-render
    }
    return false
  }
)

// * --------------------------------------------------------------------- INTERVAL ----------------------------------------------------------------------------------------
const IntervalDateComponent = ({
  dateFormat = DAY_MONTH_YEAR,
  validity = '',
  ...props
}: IIntervalDateComponent & OtherInputProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const inputHook = useGenericInputHook(
    props.controls?.map(c => ({
      control: () => {
        return c.control([dateRange[0], dateRange[1]])
      },
      error: c.error,
    }))
  )

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const id = getUniqueId(props.id)
  const { name, onBlur, readonly, clearable, required, disabled,isDisable, onChangeState, onChange } = props
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    (props.startDate && mvDate.getDateFromString(props.startDate)) || null,
    (props.endDate && mvDate.getDateFromString(props.endDate)) || null,
  ])
  const [startDate, endDate] = dateRange

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    inputHook.retry()
  }, [dateRange])

  useEffect(() => {
    if (onChangeState) {
      onChangeState(inputHook.state)
    }
  }, [inputHook.state])

  useEffect(() => {
    if (props.overrideValue) {
      setDateRange(prev => [(props.startDate && mvDate.getDateFromString(props.startDate)) || null, prev[1]])
    }
  }, [props.startDate])

  useEffect(() => {
    if (props.overrideValue) {
      setDateRange(prev => [prev[0], (props.endDate && mvDate.getDateFromString(props.endDate)) || null])
    }
  }, [props.endDate])

  // * -----------------------------------------------------------------------
  // * ---------------------------- RENDERs ---------------------------
  // * -----------------------------------------------------------------------
  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      name={name}
      onChange={date => {
        if (date && Array.isArray(date)) {
          const sD = date[0]
          const fD = date[1]
          setDateRange([date[0], date[1]])
          onChange?.([sD, fD])
        } else {
          setDateRange([null, null])
          onChange?.([null, null])
        }
      }}
      // disabledKeyboardNavigation=true remove the coloring of same day in other months
      disabledKeyboardNavigation={true}
      portalId={id}
      onBlur={onBlur}
      readOnly={readonly}
      isClearable={clearable}
      required={required}
      dateFormat={dateFormat}
      className={`mv-form-control ${validity}`}
      wrapperClassName={`mv-datepicker-wrapper mv-form-control ${validity}`}
      popperClassName={`mv-datepicker-popper`}
      clearButtonClassName={'mv-date-clear-button'}
      autoComplete="off"
      minDate={(props.min && mvDate.getDateFromString(props.min)) || undefined}
      maxDate={(props.max && mvDate.getDateFromString(props.max)) || undefined}
      disabled={disabled || isDisable}
    />
  )
}

export default DateComponent
