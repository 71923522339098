// * -------------------------------- MODULE --------------------------------------
import { BaseSemantics, IsDisable, IsLoading } from '../../../mvtypes/base'

export interface BaseButtonProps extends IsDisable, IsLoading {
  label?: string
  variant?: ButtonVariants
  semantic: ButtonSemantics
  displayAsText?: boolean
  size?: ButtonSize
  dropDown?: boolean
  type?: ButtonTypes
  onBlur?: () => void
  onFocus?: () => void
  tabIndex?: number
}

export type ButtonTypes = 'button' | 'submit' | 'reset' | undefined

export type ButtonSemantics = BaseSemantics | 'brand' | 'success' | 'warning'

export enum ButtonVariants {
  normal = '',
  outline = 'outline',
  flat = 'flat',
}

export type IconVariants = 'trash' | 'filter' | 'calendar' | 'search' | 'file' | 'clock'

export type ButtonSize = 'tiny' | 'sm' | 'md' | 'lg'

export const renderButtonStyle = (
  semantic: ButtonSemantics = 'secondary',
  variant: ButtonVariants = ButtonVariants.normal,
  size: ButtonSize = 'md',
  displayAsText: boolean = false
) => {
  return `${variant ? `${variant}-` : ''}${semantic} btn-${size}${displayAsText ? ' mv-btn--display-as-text ' : ''}`
}
