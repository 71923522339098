// * -------------------------------- NPM --------------------------------------
import React, { useEffect } from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import { instanceOf } from '@mv-submodules/inplant-components-fe/mvfunctions/helpers/objectHelper'
import { SidebarCounter } from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/SideBar/SideBarItem'

const COUNTERS_STORAGE_KEY = 'counters'

interface CountersContext {
  counters: { [key: string]: SidebarCounter }
  setCounters: (counters: SidebarCounter | { [key: string]: SidebarCounter }) => void
  clean: () => void
}

const CountersContext: React.Context<CountersContext> = React.createContext<CountersContext>({
  counters: {},
  setCounters: ({}) => null,
  clean: () => null,
})

export const CountersProvider: React.FC<{}> = props => {
  const [counters, setCounters] = React.useState<{ [key: string]: SidebarCounter }>({})

  useEffect(() => {
    const localStorageCounters = localStorage.getItem(COUNTERS_STORAGE_KEY)
    if (localStorageCounters) {
      setCounters(JSON.parse(localStorageCounters))
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(COUNTERS_STORAGE_KEY, JSON.stringify(counters))
  }, [counters])

  const handleUpdateCounters = (newCounters: SidebarCounter | { [key: string]: SidebarCounter }) => {
    if (instanceOf<SidebarCounter>(newCounters, ['count'])) {
      handleNewCounter(newCounters)
      return
    }
    handleNewCounters(newCounters)
  }

  const handleNewCounter = (counter: SidebarCounter) => {
    setCounters(prev => ({ ...prev, [counter.storeKey]: counter }))
  }

  const handleNewCounters = (newCounters: { [key: string]: SidebarCounter }) => {
    setCounters(prev => ({ ...prev, ...newCounters }))
  }

  const cleanCounters = () => {
    localStorage.removeItem(COUNTERS_STORAGE_KEY)
    setCounters({})
  }

  return (
    <CountersContext.Provider value={{ counters, setCounters: handleUpdateCounters, clean: cleanCounters }}>
      {props.children}
    </CountersContext.Provider>
  )
}

export const useCounters = () => {
  const countersContext = React.useContext(CountersContext)

  return {
    counters: countersContext.counters,
    updateCounters: countersContext.setCounters,
    clean: countersContext.clean,
  }
}

export const createStoreKey = (module: string, pageSlug: string) => `${module}.${pageSlug}`

export default CountersProvider
