const de = {
  tutelage: {
    list: {
      target: 'Maschinen',
    },
    taskCreation: {
      target: {
        title: 'Maschinen',
        asset: {
          label: 'Maschinen',
          radio: 'Spezifische Maschinen',
          sel: 'Ausgewählte Maschine',
          sels: 'Ausgewählte Maschinen',
          found: '{{value}} von {{totalValue}} gefundenen Maschinen {{valueSpecific}}'
        },
        group: {
          radio: 'Maschinengruppen',
          sel: 'Ausgewählte Maschinengruppe',
          sels: 'Ausgewählte Maschinengruppen',
          found: '{{value}} von {{totalValue}} gefundenen Maschinengruppen {{valueSpecific}}'
        },
        checkbox: {
          label: '{{value}} auf die Maschinen anwenden',
        },
        radio: {
          specific: "Eine spezifische {{value}} für jede betroffene Maschine erstellen",
          unique: "Eine einzige {{value}} für alle betroffenen Maschinen erstellen"
        },
      },
    },
    detail: {
      target: 'Maschinen',
    },
  },
}

export default de
