import en from './overwrites/en'
import it from './overwrites/it'
import es from './overwrites/es'
import de from './overwrites/de'
import fr from './overwrites/fr'

export default {
  en,
  it,
  es,
  de,
  fr
}
