import { ActionCreator, Dispatch } from 'redux'
import { AppManagerPersist } from '@mv-submodules/inplant-core-fe/redux/reducers'

export const TOGGLE_COLLAPSE_MENU = 'TOGGLE_COLLAPSE_MENU'


interface ToggleCollapseMenu {
  type: typeof TOGGLE_COLLAPSE_MENU,
  payload: boolean
}

const actionToggleCollapseMenu: ActionCreator<ToggleCollapseMenu> = (isCollapsed:boolean): ToggleCollapseMenu => {
  return {
    type: TOGGLE_COLLAPSE_MENU,
    payload: isCollapsed
  }
}

export const toggleCollapseMenu = (isCollapsed:boolean) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionToggleCollapseMenu(isCollapsed))
}


export type SettingsActions = ToggleCollapseMenu | AppManagerPersist