// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import * as qs from 'query-string'

// * -------------------------------- MODULE --------------------------------------
import { loginUserByJwt } from '../../../../redux/actions/auth'

interface QueryStringProps {
  jwt: string
  redirect: string
}

interface DispatchProps {
  loginUserByJwt: (jwt: string) => Promise<void>
}

interface OwnProps extends RouteComponentProps<QueryStringProps> {}

type Props = DispatchProps & OwnProps

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    loginUserByJwt: jwt => dispatch(loginUserByJwt(jwt)),
  }
}

class Webview extends React.Component<Props> {
  public constructor(props: Props) {
    super(props)
  }

  public async componentDidMount() {
    try {
      const { jwt, redirect } = qs.parse(this.props.location.search)
      if (jwt && redirect) {
        await this.props.loginUserByJwt(jwt as string)
        return this.props.history.push(redirect as string)
      }
      return this.props.history.push('/webview?action=logout')
    } catch {
      return this.props.history.push('/webview?action=logout')
    }
  }

  public render() {
    return null
  }
}

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Webview)
)
