// * -------------------------------- NPM --------------------------------------
import React, { useEffect } from 'react'
import { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Flex, { AlignItems, Column, Direction, Fit } from '../../MVFlex/Flex'
import SelectComponent from './SelectComponent'
import useGenericInputHook from '../../../../mvfunctions/hooks/useGenericInputHook'
import { Controls } from '../../../../mvfunctions/stateValidation'
import { GlobalProps, OtherInputProps } from '../types'
import { mvDate } from '../../../../mvfunctions/helpers/dateHelper'
import { useComponentsTranslation } from '../../../../services/translation'

/**
 * !Don't use this component, Use only Input
 *
 * This component expose a @callback onChange that return the current value and the state of the input
 *
 * Use the props @param controls to create the controls that the input need to satisfy to be valid
 *
 * @param initialTime default option for the select
 *
 * @param startTime startTime for range time selectable
 * @param endTime endTime for range time selectable
 */

export interface IDiscreteTimeComponents extends GlobalProps<'time'> {
  timeType: 'discrete'
  onTimeChange: (time: Date) => void

  initialTime: Date

  startTime: Date
  endTime: Date
  minuteDiscreteInterval: number
  controls?: Controls<Date>

  showLabels?: boolean
}

const DiscreteTimeComponent = (props: IDiscreteTimeComponents & OtherInputProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const inputHook = useGenericInputHook(props.controls?.map(c => ({ control: () => c.control(time), error: c.error })))

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { startTime, endTime, minuteDiscreteInterval, onTimeChange } = props
  const translation = useComponentsTranslation()
  const base = 'components.input.discreteTime'
  // !hours are managed as fullDate to guarantee the correctness of interval across days
  const [date, /* setDate */] = useState<Date>(props.initialTime)
  const [hours, setHours] = useState<string>(mvDate.format(props.initialTime, 'HH'))
  const [minutes, setMinutes] = useState<string>(mvDate.format(props.initialTime, 'mm'))

  // !the value returned
  const [time, setTime] = useState<Date>(props.initialTime)

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const generateHours = (): Date[] => {
    const allPossibleHours: Date[] = []

    let counter = startTime

    while (mvDate.isSameOrBeforeHour(counter, endTime)) {
      let currentCounter = mvDate.toDate(counter)
      currentCounter = mvDate.setMinutes(currentCounter, 0)
      allPossibleHours.push(currentCounter)
      counter = mvDate.addHours(counter, 1)
    }

    return allPossibleHours
  }

  const generateMinutes = () => {
    const allPossibleMinutes: string[] = []

    let templateDate = mvDate.now()
    templateDate = mvDate.setMinutes(templateDate, 0)
    for (let min = 0; min < 60; min += minuteDiscreteInterval) {
      allPossibleMinutes.push(mvDate.format(templateDate, 'mm'))
      templateDate = mvDate.addMinutes(templateDate, minuteDiscreteInterval)
    }
    return allPossibleMinutes
  }

  const [allHours] = useState<Date[]>(generateHours())
  const [allMinutes] = useState<string[]>(generateMinutes())

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    setTime(mvDate.setHours(mvDate.setMinutes(date, Number(minutes)),Number(hours)))
  }, [minutes, hours])

  useEffect(() => {
    onTimeChange(time)
    inputHook.retry()
  }, [time])

  useEffect(() => {
    if (props.onChangeState) {
      props.onChangeState(inputHook.state)
    }
  }, [inputHook.state])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs -----------------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <Flex direction={Direction.row} alignItems={AlignItems.end} fit={Fit.oneLine}>
      <Column>
        {(props.showLabels && <label>{translation.t(`${base}.hours.label`)}</label>) || null}
        <SelectComponent
          type={'singleSelect'}
          name={props.name}
          readonly={props.readonly}
          validity={props.validity}
          onBlur={props.onBlur}
          kind={'select'}
          onChange={value => {
            setHours(value)
          }}
          options={{
            defaultOption: {
              value: `${mvDate.format(props.initialTime,'HH')}`,
              disable: false,
            },
            items: allHours.map(t => {
              return { value: mvDate.format(t, 'HH'), label: mvDate.format(t, 'HH')}
            }),
          }}
        />
      </Column>

      <Column>
        {(props.showLabels && <label>{translation.t(`${base}.minutes.label`)}</label>) || null}
        <SelectComponent
          type={'singleSelect'}
          name={props.name}
          readonly={props.readonly}
          validity={props.validity}
          kind={'select'}
          onChange={value => {
            setMinutes(value)
          }}
          options={{
            defaultOption: {
              value: `${mvDate.format(props.initialTime, 'mm')}`,
              disable: false,
            },
            items: allMinutes.map(m => {
              return { value: m, label: m }
            }),
          }}
        />
      </Column>
    </Flex>
  )
}

export default DiscreteTimeComponent
