// * -------------------------------- NPM --------------------------------------
import React from 'react'
import { useLocation } from 'react-router'

// * -------------------------------- MODULE --------------------------------------
import Button from '../MVButtons/Button'
import { ButtonVariants } from '../MVButtons/types'
import { LinkableText } from '../../../ui/components/MVText/Text'
import { RouteProps, translateRouteKey } from '../../../ui/components/MVLayout/SideBar/SideBar'
import { useComponentsTranslation } from '../../../services/translation'
import { uuidRegExp } from '../../../mvfunctions/RegExp'

export interface BreadcrumbsProps {
  onClickMobileMenu: () => void
  routes: RouteProps[]
  segmentsName?: { [uuid: string]: string }
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const location = useLocation()
  const translation = useComponentsTranslation()

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const mvb = 'mv-breadcrumb'
  const { segmentsName } = props

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const flatRoutes = props.routes.reduce<RouteProps[]>(
    (acc, val) => acc.concat(...((val.children && val.children) || [])),
    [...props.routes]
  )

  const createBreadcrumbs = (): string[] => {
    const paths = location.pathname.split('/')
    paths.shift()
    return paths
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const splitBreadcrumbs = createBreadcrumbs()
  return (
    <nav aria-label={'breadcrumb'} className={`${mvb}-container`}>
      <div className="mv-navigation__handle">
        <Button semantic={'secondary'} variant={ButtonVariants.flat} icon={'bars'} onClick={props.onClickMobileMenu} />
      </div>
      <ol className={mvb}>
        {createBreadcrumbs().map((p: string, idx: number) => {
          let text = ''
          if (!p.match(uuidRegExp)) {
            text = p
          }

          if (segmentsName && segmentsName[p]) {
            text = segmentsName[p]
          } else {
            const found = flatRoutes.find(el => el.breadcrumbPath === p && el.i18nkey)
            if (found) {
              text = translateRouteKey(translation, found)
            }
          }

          text = text.toLowerCase()

          // first element (module name) and last (current) not a link
          return (
            <li className={`${mvb}__item`} key={`${idx}`}>
              {idx >= 1 && idx < splitBreadcrumbs.length - 1 ? (
                <LinkableText
                  to={loc => ({
                    ...loc,
                    pathname: `${loc.pathname.slice(0, loc.pathname.indexOf(p) + p.length)}`,
                  })}
                  text={text}
                />
              ) : (
                <span>{text}</span>
              )}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
