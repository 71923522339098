const locales = Object.freeze({
  it: {
    extensionName: 'it',
    importName: 'it',
  },
  en: {
    extensionName: 'en-GB',
    importName: 'enGB',
  },
  de: {
    extensionName: 'de',
    importName: 'de',
  },
  es: {
    extensionName: 'es',
    importName: 'es',
  },
  fr: {
    extensionName: 'fr',
    importName: 'fr',
  }
})

export async function getLocale(language: keyof typeof locales): Promise<Locale> {
  const { default: importedLocale } = await import(`date-fns/locale/${locales[language].extensionName}`)
  return importedLocale
}

export async function getLocales(languages: Array<keyof typeof locales>): Promise<Locale[]> {
  const imports = languages.map(getLocale)
  return Promise.all(imports)
}

export let localeSupported: { [language: string]: Locale } = {}

export function addLocaleSupport(language: string, locale: Locale) {
  localeSupported[language] = locale
}

export function addLocalesSupport(newLocales: Array<{ language: string; locale: Locale }>) {
  for (const l of newLocales) {
    addLocaleSupport(l.language, l.locale)
  }
}

export function removeLocaleSupport(language: string) {
  delete localeSupported[language]
}

export function removeLocalesSupport(languages: string[]) {
  for (const l of languages) {
    removeLocaleSupport(l)
  }
}

export function setLocalesSupport(newLocales: Array<{ language: string; locale: Locale }>) {
  localeSupported = {}
  addLocalesSupport(newLocales)
}
