// * -------------------------------- NPM --------------------------------------
import { combineReducers } from 'redux'

// * -------------------------------- MODULE --------------------------------------
import { pageConfig } from './pageConfig'

const tutelageReducersMap = {
  pageConfig,
}

const tutelageControlReducers = combineReducers(tutelageReducersMap)

export default tutelageControlReducers
