// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Flex, { AlignItems, Fit, JustifyContent } from '../MVFlex/Flex'

interface Props {
  logo: {
    path: string
  }
  actions?: React.ReactNode
  leftComponents?: Array<JSX.Element | React.Component>
}

const TopBar = (props: Props) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const t = `mv-topbar`

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <header className={`${t}`}>
      <Flex alignItems={AlignItems.center} justifyContent={JustifyContent.between} fit={Fit.oneLine}>
        <Flex>
          <img src={props.logo.path} className={`${t}__logo`} title="logo" alt={'logo'} />
          {props.leftComponents?.map((c, index) => (
            <div key={index} className={`${t}__wrapper`}>
              {c}
            </div>
          ))}
        </Flex>
        <Flex justifyContent={JustifyContent.end}>{props.actions}</Flex>
      </Flex>
    </header>
  )
}

export default TopBar
