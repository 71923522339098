// * -------------------------------- NPM --------------------------------------
import * as H from 'history'
import React from 'react'
import { Link } from 'react-router-dom'
import { IsDisable } from '../../../mvtypes/base'

// * -------------------------------- MODULE --------------------------------------
import { IFlexItem, renderFlexItem } from '../MVFlex/FlexItem'

export interface LinkableProps<S = H.LocationState> extends IFlexItem, IsDisable {
  to?: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>)
  href?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  replace?: boolean
}

const Linkable = <P extends Object>(Component: React.ComponentType<P>) =>
  class LinkComponent extends React.Component<P & LinkableProps> {
    public render() {
      const { to, href, onClick, replace, ...props } = this.props

      const flexProps = renderFlexItem({ ...this.props })

      if (props.isDisable) {
        return <Component {...(props as P)} />
      }

      // let the case separated for a cleaner comprehension
      if (to) {
        return (
          <Link className={`mv-anchor-reset ${flexProps}`} to={to} replace={replace}>
            <Component {...(props as P)} />
          </Link>
        )
      }

      if (href) {
        return (
          <a href={href} className={` ${flexProps}`}>
            <Component {...(props as P)} />
          </a>
        )
      }

      if (onClick) {
        return (
          <a onClick={onClick} className={` ${flexProps}`}>
            <Component {...(props as P)} />
          </a>
        )
      }

      return (
        <a className={` ${flexProps}`}>
          <Component {...(props as P)} />
        </a>
      )
    }
  }

export default Linkable
