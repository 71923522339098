import { addLocalesSupport, getLocales } from "../inplant-components-fe/mvfunctions/helpers/dateLocaleHelper"

export async function onStartup(): Promise<void> {
  getLocales(['it', 'en', 'de', 'es', 'fr']).then(locales => {
    addLocalesSupport([
      {
        language: 'it',
        locale: locales[0],
      },
      {
        language: 'en',
        locale: locales[1],
      },
      {
        language: 'de',
        locale: locales[2],
      },
      {
        language: 'es',
        locale: locales[3],
      },
      {
        language: 'fr',
        locale: locales[4],
      }
    ])
  })
}