// * -------------------------------- NPM --------------------------------------
import { useEffect, useState } from 'react'

interface Control {
  control: () => boolean
  error: string
}

/**
 *
 * @param controls controllers to do
 * @param callback function fire after the controls execution
 * @returns state of the hook: if all controls return true then is valid else, is invalid with the message of the first not valid controls
 */
const useGenericInputHook = (controls: Control[] = []) => {
  const [state, setState] = useState<StateType>({ kind: 'notValid', message: '' })

  const [retryCount, setRetryCount] = useState<number>(0)

  useEffect(() => {
    const validityControlArray: StateType[] = []

    controls.forEach(c => {
      if (c.control()) {
        validityControlArray.push({ kind: 'valid' })
      } else {
        validityControlArray.push({ kind: 'notValid', message: c.error })
      }
    })

    let firstErrorMessage: string | undefined

    const val = validityControlArray.reverse().reduce((acc, current) => {
      if (current.kind === 'notValid') {
        firstErrorMessage = current.message
        return false
      } else {
        return acc
      }
    }, true)

    if (val) {
      setState({ kind: 'valid' })
    } else {
      setState({ kind: 'notValid', message: firstErrorMessage! })
    }
  }, [retryCount])

  // TODO mettere che al unmount ritorna uno stato valido ? (al momento solo dentro gli input)
  /* useEffect(() => {
    return () => {
      consoleLog("chiamato")
    }
  }, []) */
  

  return {
    state,
    retry: () => setRetryCount(prev => prev + 1),
  }
}

export interface IsValid {
  kind: 'valid'
}

export interface IsNotValid {
  kind: 'notValid'
  message: string
}

// ------------------------------------------------------------------------------
// --------------------------------- INTERFACES ---------------------------------
// ------------------------------------------------------------------------------
export type StateType = IsValid | IsNotValid

export interface ObjValidity {
  [key: string]: StateType | StateType[] | undefined
}

// ------------------------------------------------------------------------------
// ---------------------------- HELPERS FOR VALIDITY ----------------------------
// ------------------------------------------------------------------------------
export const arrayValidity = (array: StateType[]): boolean => {
  const v = array.reduce((acc, val) => {
    return val.kind === 'valid' && acc
  }, true)
  return v
}

export const objectValidity = (obj: ObjValidity): boolean => {
  const oValidity = reduceObjectToArray(obj)
  return arrayValidity(oValidity as StateType[])
}

// ------------------------------------------------------------------------------
// ---------------------------- HELPERS FOR MESSAGES ----------------------------
// ------------------------------------------------------------------------------
export const getMessage = (state: StateType | StateType[] | undefined): string => {
  let notValidState: IsNotValid | undefined
  if (Array.isArray(state)) {
    notValidState = state.find(s => s.kind === 'notValid') as IsNotValid | undefined
  } else {
    notValidState = state && state.kind === 'notValid' ? state : undefined
  }
  return notValidState ? notValidState.message : ''
}

export const getMessages = (state: StateType[]): string[] => {
  return state.filter(s => s.kind === 'notValid').map(s => getMessage(s))
}

export const getMessageFromObjValidity = (obj: ObjValidity): string => {
  const oValidity = reduceObjectToArray(obj)
  return getMessage(oValidity)
}

export const getMessagesFromObjValidity = (obj: ObjValidity): string[] => {
  const oValidity = reduceObjectToArray(obj)
  return getMessages(oValidity)
}

const reduceObjectToArray = (obj: ObjValidity): StateType[] => {
  return Object.values(obj).map(s => {
    if (Array.isArray(s)) {
      return s.reduce(
        (acc, val) => {
          if (val && val.kind === 'notValid') {
            return val
          }
          return acc
        },
        { kind: 'valid' },
      )
    } else {
      return s
    }
  }) as StateType[]
}


export default useGenericInputHook
