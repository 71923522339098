import { RouteProps as RP } from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/SideBar/SideBar'

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

// interface BE data Legacy login
export interface LegacyUserParams {
  uuid: string
  username: string
  displayName: string
  roles: string[]
  forbiddenActions: string[]
}

// interface BE data new Login
export interface VidimCoreAuth {
  token: string
  user: VidimCoreUser
}

export interface VidimCoreUser {
  uuid: string
  firstName: string
  lastName: string
  userName: string
  email?: string
  roles: string[]
  aclRules: string[]
  properPositionUuid: string
  derivedPositions: string[]
  uiTheme?: string
}


// interface accepted from FE User class (middle interface that can accept both LegacyUserParams and VidimCoreUser)
export interface UserParams {
  uuid: string
  username: string
  displayName: string
  roles: string[]
  forbiddenActions: string[]
  aclRules: {[acl:string]:boolean}
  uiTheme?: string
}

export interface Branding {
  uiTheme: string
}

// user used (and saved) around Vidim
export class User {
  public uuid: string
  public username: string
  public displayName: string
  public roles: string[]
  public forbiddenActions: string[]
  public aclRules: {[acl:string]:boolean}
  public branding: Branding

  constructor(params: Partial<UserParams>) {
    this.uuid = params.uuid || ''
    this.username = params.username || ''
    this.displayName = params.displayName || ''
    this.roles = params.roles || []
    this.forbiddenActions = params.forbiddenActions || []
    this.aclRules = params.aclRules || {}
    this.branding = params.uiTheme && {uiTheme: params.uiTheme} || {uiTheme: ''}
  }

  public can(action: string): boolean {
    return this.canDo(action) || this.canView(action)
  }

  private canDo(action:string):boolean {
    return this.aclRules[action]
  }

  private canView(action:string): boolean {
    return this.aclRules[`view.${action}`]
  }
}

export type RouteProps = RP
